.likesContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: auto;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: var(--controls-grey);
}

.likesCount {
  display: flex;
  align-items: center;
  gap: 4px;
}

.likesCount svg {
  width: 22px;
  height: 22px;
}

.likeBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 6px;
  height: unset;
  color: inherit;
}

.likeBtn:hover {
  color: var(--button-green);
}

.likeBtn:hover svg {
  fill: #2b9df6;
}