.tagsPopup {
  max-width: 250px;
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 15px 12px 12px;
  background-color: #fff;
  box-shadow: 0 2px 4px 2px var(--popup-shadow);
  border-radius: 4px;
  z-index: 1;
}

.tagsPopupEdit {
  top: unset;
  left: 10px;
  bottom: 40px;
}

.popupHeader {
  display: flex;
  justify-content: space-between;
}

.popupTitle {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.addTagButton,
.buttonIcon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.inputTitle {
  margin-bottom: 4px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
}

.searchInputNoSearchResults {
  margin-bottom: 0;
}

.tagsGroupTitle {
  font-size: 11px;
  color: var(--main-text-color);
  font-weight: 700;
}

.tabsContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tagsTab {
  cursor: pointer;
}

.tagsTabActive {
  text-decoration: underline;
  text-underline-position: under;
}

.tagList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  max-height: 312px;
  margin-right: -12px;
  padding-right: 12px;
}

.noResults {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 20px 0;
}

.noResultsIcon {
  width: 24px;
  height: 24px;
}

.createTagButton {
  width: 100%;
  height: unset;
  padding: 4px 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  background-color: var(--user-post-bg);
  border-radius: 12px;
  word-break: break-word;
}

.accessSelect {
  width: 100%;
  height: 28px;
  background-color: var(--user-post-bg);
}

.colorsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.colorButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: orange;
  outline: 2px solid transparent;
}

.colorButton:hover {
  opacity: 0.9;
}

.selectedColor {
  border: 2px solid #fff;
}

.checkMarkIcon {
  color: #fff;
}

.popupControls {
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

.button {
  width: 90px;
  padding: 4px 0;
  height: 24px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 16px;
}

.requiredField {
  position: absolute;
  bottom: -16px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 10px;
  color: var(--badge-red);
}

.errorIcon {
  width: 14px;
  height: 14px;
}
