.themes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
}

.modalTitle {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}