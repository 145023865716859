.playButton {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: var(--btn-shadow);
  backdrop-filter: blur(2px);
  border-radius: 50%;
  z-index: 1;
}

.playButton:hover {
  background-color: var(--popup-shadow);
}

.playButtonIcon {
  width: 32px;
  height: 32px;
  color: #fff;
}

@media (min-width: 768px) {
  .playButton {
    width: 60px;
    height: 60px;
  }

  .playButtonIcon {
    width: 40px;
    height: 40px;
  }
}