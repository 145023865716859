.container {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  z-index: 2;
}

.container:focus-visible {
  outline: none;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalHeading {
  margin: 0;
}

.horizontalLine {
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.declineButton {
  background-color: #eff1f5;
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 900px;
  }
}
