.badge {
  position: relative;
  line-height: 1;
}

.badgeOutlined {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number {
  background-color: var(--badge-red);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  font-size: 10px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -7px;
  top: -7px;
  min-width: 18px;
  height: 18px;
  text-align: center;
}

.badgeOutlined .number {
  right: -4px;
  top: -2px;
}

button .number {
  padding: 2px 2px;
}

.peopleItemBadge {
  right: -10px;
  top: -10px;
}

.greenNumbers {
  background: var(--button-green);
}
