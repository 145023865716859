#divSnippetHandle {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.wcc-editor-content {
  font-family: "Open Sans", sans-serif, Arial;
  font-size: 14px;
  line-height: 1.7;
  /* WCC fix for pasted text */
  /* FIX: Preventing Chrome from wrapping text with span-style (when editing) */
  /********************************** Grid ***********************************/
  /********************************** Elements ***********************************/
  /********************************** Header Image with Caption ***********************************/
  /* Text */
  /* Cosmetic */
  /* One */
  /* Two */
  /* Three */
  /* Four */
  /* Five */
  /* Six */
  /* Seven */
  /* Eight */
}
.wcc-editor-content h1, .wcc-editor-content h2, .wcc-editor-content h3, .wcc-editor-content h4, .wcc-editor-content h5, .wcc-editor-content h6 {
  font-family: "Open Sans", sans-serif, Arial;
  font-weight: 600;
}
.wcc-editor-content h1 {
  font-size: 2.37em;
}
.wcc-editor-content h2 {
  font-size: 1.83em;
}
.wcc-editor-content h3 {
  font-size: 1.39em;
}
.wcc-editor-content h4 {
  font-size: 1.1em;
}
.wcc-editor-content h5 {
  font-size: 0.98em;
}
.wcc-editor-content h6 {
  font-size: 0.85em;
}
.wcc-editor-content .display h1 {
  font-weight: 800;
  font-size: 2.8em;
  line-height: 1.4;
  text-transform: uppercase;
}
.wcc-editor-content .display > p {
  font-size: 1.5em;
  font-style: italic;
}
.wcc-editor-content a {
  color: #428bca;
  word-break: break-word;
}
.wcc-editor-content hr {
  border: none;
  border-top: rgba(0, 0, 0, 0.18) 1px solid;
  margin-top: 2em;
  margin-bottom: 2em;
}
.wcc-editor-content img {
  max-width: 100%;
}
.wcc-editor-content figure {
  margin: 0;
}
.wcc-editor-content .display h1 span {
  font-size: 2.8em;
  line-height: 1.4;
}
.wcc-editor-content .display p span {
  font-size: 1.5em;
  line-height: 1.7;
}
.wcc-editor-content h1 span {
  font-size: 2.37em;
  line-height: 1.7;
}
.wcc-editor-content h2 span {
  font-size: 1.83em;
  line-height: 1.7;
}
.wcc-editor-content h3 span {
  font-size: 1.39em;
  line-height: 1.7;
}
.wcc-editor-content h4 span {
  font-size: 1.1em;
  line-height: 1.7;
}
.wcc-editor-content h5 span {
  font-size: 0.98em;
  line-height: 1.7;
}
.wcc-editor-content h6 span {
  font-size: 0.85em;
  line-height: 1.7;
}
.wcc-editor-content p span {
  font-size: 14px;
  line-height: 1.7;
}
.wcc-editor-content li span {
  font-size: 14px;
  line-height: 1.7;
}
.wcc-editor-content .container {
  margin: 0 auto;
  max-width: 970px;
  width: 90%;
  margin-top: 0 !important;
  padding-top: 35px;
  padding-bottom: 35px;
  min-height: 100px;
}
@media (min-width: 501px) {
  .wcc-editor-content .column {
    float: left;
    padding-left: 1.3rem;
    /* 1rem */
    padding-right: 1.3rem;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .wcc-editor-content .column.full {
    width: 100%;
  }
  .wcc-editor-content .column.two-thirds {
    width: 66.7%;
  }
  .wcc-editor-content .column.two-fourth {
    width: 75%;
  }
  .wcc-editor-content .column.two-fifth {
    width: 80%;
  }
  .wcc-editor-content .column.two-sixth {
    width: 83.3%;
  }
  .wcc-editor-content .column.half {
    width: 50%;
  }
  .wcc-editor-content .column.third {
    width: 33.3%;
  }
  .wcc-editor-content .column.fourth {
    width: 25%;
  }
  .wcc-editor-content .column.fifth {
    width: 20%;
  }
  .wcc-editor-content .column.sixth {
    width: 16.6%;
  }
  .wcc-editor-content .column.flow-opposite {
    float: right;
  }
}
.wcc-editor-content .clearfix:before, .wcc-editor-content .clearfix:after {
  content: " ";
  display: table;
}
.wcc-editor-content .clearfix:after {
  clear: both;
}
.wcc-editor-content .clearfix {
  *zoom: 1;
}
.wcc-editor-content .center {
  text-align: center;
}
.wcc-editor-content .right {
  text-align: right;
}
.wcc-editor-content .left {
  text-align: left;
}
.wcc-editor-content img.circle {
  border-radius: 500px;
  margin-top: 0;
}
.wcc-editor-content img.bordered {
  border: #ccc 1px solid;
}
.wcc-editor-content .embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.wcc-editor-content .embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.wcc-editor-content .embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.wcc-editor-content .embed-responsive iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.wcc-editor-content .column > img, .wcc-editor-content .column > figure, .wcc-editor-content .column > .embed-responsive {
  margin-top: 1em;
  margin-bottom: 1em;
}
.wcc-editor-content .list {
  position: relative;
  margin: 1.5em 0;
}
.wcc-editor-content .list > i {
  position: absolute;
  left: -3px;
  top: -3px;
  font-size: 2em;
}
.wcc-editor-content .list > h2, .wcc-editor-content .list > h3 {
  margin: 0 0 0 50px;
}
.wcc-editor-content .list > p {
  margin: 0 0 0 50px;
}
.wcc-editor-content .quote {
  position: relative;
  margin: 1.5em 0;
}
.wcc-editor-content .quote > i {
  position: absolute;
  top: -10px;
  left: -7px;
  font-size: 2em;
  color: rgba(51, 51, 51, 0.44);
}
.wcc-editor-content .quote > small {
  margin-left: 50px;
  opacity: 0.7;
  font-size: 1em;
}
.wcc-editor-content .quote > p {
  margin-left: 50px;
  font-size: 1.5em;
}
.wcc-editor-content .btn {
  padding: 10px 30px;
  font-size: 1.3em;
  line-height: 2em;
  border-radius: 6px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.wcc-editor-content .btn.btn-primary {
  color: #fff;
  background-color: #08c9b9;
}
.wcc-editor-content .btn.btn-primary:hover {
  color: #fff;
  background-color: #07b0a2;
  border-color: #07b0a2;
}
.wcc-editor-content .btn.btn-default {
  color: #333;
  background-color: #d3d3d3;
}
.wcc-editor-content .btn.btn-default:hover {
  color: #111;
  background-color: #ccc;
  border-color: #ccc;
}
.wcc-editor-content .social a > i {
  text-decoration: none;
  color: #333;
  font-size: 1.5em;
  margin: 0 5px 0 0;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.wcc-editor-content .social a:hover > i {
  color: #08c9b9;
}
.wcc-editor-content figure.hdr {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #000;
}
.wcc-editor-content figure.hdr img {
  position: relative;
  display: block;
  width: 100%;
  opacity: 0.8;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.wcc-editor-content figure.hdr:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.wcc-editor-content figure.hdr figcaption {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  padding: 0 2.5em;
  color: #fff;
  font-size: 1.55em;
  text-align: center;
  box-sizing: border-box;
  z-index: 1;
}
.wcc-editor-content figure.hdr h2 {
  font-weight: 300;
  text-transform: uppercase;
}
.wcc-editor-content figure.hdr h2 span {
  font-weight: 800;
}
.wcc-editor-content figure.hdr p {
  letter-spacing: 1px;
  font-size: 68.5%;
  text-transform: uppercase;
}
.wcc-editor-content figure.hdr h2, .wcc-editor-content figure.hdr p {
  margin: 0;
  z-index: 10000;
}
.wcc-editor-content figure.hdr div {
  height: 100%;
  z-index: 0;
}
.wcc-editor-content figure.hdr div::before, .wcc-editor-content figure.hdr div::after {
  position: absolute;
  content: '';
}
.wcc-editor-content figure.one div::before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.wcc-editor-content figure.one div::after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.wcc-editor-content figure.two div::before {
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.wcc-editor-content figure.two div::after {
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.wcc-editor-content figure.three figcaption {
  height: 70%;
}
.wcc-editor-content figure.three p {
  margin: 1em 0 0;
  padding: 2em;
  border: 1px solid #fff;
}
.wcc-editor-content figure.four figcaption {
  height: 60%;
  text-align: left;
}
.wcc-editor-content figure.four p {
  position: absolute;
  right: 50px;
  bottom: 50px;
  left: 50px;
  padding: 2em;
  border: 7px solid #fff;
}
.wcc-editor-content figure.five figcaption {
  height: 100%;
  text-align: right;
}
.wcc-editor-content figure.five h2 {
  position: absolute;
  left: 50px;
  right: 50px;
  top: 10%;
  border-bottom: 5px solid #fff;
}
.wcc-editor-content figure.five p {
  position: absolute;
  right: 50px;
  bottom: 10%;
}
.wcc-editor-content figure.six figcaption {
  height: 70%;
}
.wcc-editor-content figure.six h2 {
  padding-bottom: 3%;
  border-bottom: 1px solid #fff;
}
.wcc-editor-content figure.six p {
  padding-top: 6%;
}
.wcc-editor-content figure.seven figcaption {
  height: 90%;
  text-align: left;
}
.wcc-editor-content figure.seven h2 {
  border-bottom: 3px solid #fff;
}
.wcc-editor-content figure.seven p {
  padding-top: 1em;
}
.wcc-editor-content figure.eight figcaption {
  height: 100%;
  text-align: right;
}
.wcc-editor-content figure.eight h2 {
  position: absolute;
  left: 50%;
  right: 50px;
  bottom: 10%;
}
.wcc-editor-content figure.eight p {
  position: absolute;
  left: 50px;
  right: 50%;
  top: 10%;
  padding-right: 0.5em;
  border-right: 1px solid #fff;
}
.wcc-editor-content .row {
  margin-left: 0;
  margin-right: 0;
}
.wcc-editor-content .wcc-editor-content.mobilePreview {
  width: 400px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview h1:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h2:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h3:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h4:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h5:first-child {
  margin-top: 10px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .column {
  float: none !important;
  width: 100% !important;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .column.imageContent {
  text-align: center;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .display h1 {
  font-size: 2.2em;
  line-height: 1.3;
}
.wcc-editor-content .wcc-editor-content.mobilePreview h1 {
  font-size: 2.2em !important;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.hdr figcaption {
  bottom: 10px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.hdr figcaption h2 {
  font-size: 1.6em;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.hdr figcaption h2 span {
  font-size: 1.4em;
  line-height: 1.4;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .btn {
  padding: 4px 17px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.two div::before, .wcc-editor-content .wcc-editor-content.mobilePreview figure.two div::after {
  border: none;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.three p {
  padding: 0.5em;
  margin: 0;
}
.wcc-editor-content .wcc-editor-content.mobilePreview {
  width: 400px;
  background-color: #fff;
  margin: 0 auto !important;
  border: 1px solid #cecece;
  padding: 10px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview h1:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h2:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h3:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h4:first-child, .wcc-editor-content .wcc-editor-content.mobilePreview h5:first-child {
  margin-top: 10px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .column {
  float: none !important;
  width: 100% !important;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .column.imageContent {
  text-align: center;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .display h1 {
  font-size: 2.2em;
  line-height: 1.3;
}
.wcc-editor-content .wcc-editor-content.mobilePreview h1 {
  font-size: 2.2em !important;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.hdr figcaption {
  bottom: 10px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.hdr figcaption h2 {
  font-size: 1.6em;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.hdr figcaption h2 span {
  font-size: 1.4em;
  line-height: 1.4;
}
.wcc-editor-content .wcc-editor-content.mobilePreview .btn {
  padding: 4px 17px;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.two div::before, .wcc-editor-content .wcc-editor-content.mobilePreview figure.two div::after {
  border: none;
}
.wcc-editor-content .wcc-editor-content.mobilePreview figure.three p {
  padding: 0.5em;
  margin: 0;
}
.wcc-editor-content .size-12 {
  font-size: 12px !important;
}
.wcc-editor-content .size-14 {
  font-size: 14px !important;
}
.wcc-editor-content .size-16 {
  font-size: 16px !important;
}
.wcc-editor-content .size-18 {
  font-size: 18px !important;
}
.wcc-editor-content .size-21 {
  font-size: 21px !important;
}
.wcc-editor-content .size-24 {
  font-size: 24px !important;
}
.wcc-editor-content .size-28 {
  font-size: 28px !important;
}
.wcc-editor-content .size-32 {
  font-size: 32px !important;
}
.wcc-editor-content .size-35 {
  font-size: 35px !important;
}
.wcc-editor-content .size-38 {
  font-size: 38px !important;
}
.wcc-editor-content .size-42 {
  font-size: 42px !important;
}
.wcc-editor-content .size-46 {
  font-size: 46px !important;
}
.wcc-editor-content .size-48 {
  font-size: 48px !important;
}
.wcc-editor-content .size-50 {
  font-size: 50px !important;
}
.wcc-editor-content .size-54 {
  font-size: 54px !important;
}
.wcc-editor-content .size-60 {
  font-size: 60px !important;
}
.wcc-editor-content .size-64 {
  font-size: 64px !important;
}
.wcc-editor-content .size-68 {
  font-size: 68px !important;
}
.wcc-editor-content .size-72 {
  font-size: 72px !important;
}
.wcc-editor-content .size-76 {
  font-size: 76px !important;
}
.wcc-editor-content .size-80 {
  font-size: 80px !important;
}
.wcc-editor-content .size-84 {
  font-size: 84px !important;
}
.wcc-editor-content .size-88 {
  font-size: 88px !important;
}
.wcc-editor-content .size-92 {
  font-size: 92px !important;
}
.wcc-editor-content .size-96 {
  font-size: 96px !important;
}
.wcc-editor-content .size-100 {
  font-size: 100px !important;
}
.wcc-editor-content .size-104 {
  font-size: 104px !important;
}
.wcc-editor-content .size-108 {
  font-size: 108px !important;
}
.wcc-editor-content .size-112 {
  font-size: 112px !important;
}
.wcc-editor-content .size-116 {
  font-size: 116px !important;
}
.wcc-editor-content .size-120 {
  font-size: 120px !important;
}
.wcc-editor-content .size-124 {
  font-size: 124px !important;
}
.wcc-editor-content .size-128 {
  font-size: 128px !important;
}
.wcc-editor-content .size-132 {
  font-size: 132px !important;
}
.wcc-editor-content .size-136 {
  font-size: 136px !important;
}
.wcc-editor-content .size-140 {
  font-size: 140px !important;
}
.wcc-editor-content .size-144 {
  font-size: 144px !important;
}
.wcc-editor-content .size-148 {
  font-size: 148px !important;
}
.wcc-editor-content .size-152 {
  font-size: 152px !important;
}
.wcc-editor-content .size-156 {
  font-size: 156px !important;
}
.wcc-editor-content .size-160 {
  font-size: 160px !important;
}
.wcc-editor-content .size-164 {
  font-size: 164px !important;
}
.wcc-editor-content .size-168 {
  font-size: 168px !important;
}
.wcc-editor-content .size-172 {
  font-size: 172px !important;
}
.wcc-editor-content .size-176 {
  font-size: 176px !important;
}
.wcc-editor-content .size-180 {
  font-size: 180px !important;
}
.wcc-editor-content .size-184 {
  font-size: 184px !important;
}
.wcc-editor-content .size-188 {
  font-size: 188px !important;
}
.wcc-editor-content .size-192 {
  font-size: 192px !important;
}
.wcc-editor-content .size-196 {
  font-size: 196px !important;
}
.wcc-editor-content .size-200 {
  font-size: 200px !important;
}
.wcc-editor-content .size-204 {
  font-size: 204px !important;
}
.wcc-editor-content .size-208 {
  font-size: 208px !important;
}
.wcc-editor-content .size-212 {
  font-size: 212px !important;
}
.wcc-editor-content .size-216 {
  font-size: 216px !important;
}
.wcc-editor-content .size-220 {
  font-size: 220px !important;
}
@media all and (max-width: 1024px) {
  .wcc-editor-content .size-12 {
    font-size: 12px !important;
  }
  .wcc-editor-content .size-14 {
    font-size: 14px !important;
  }
  .wcc-editor-content .size-16 {
    font-size: 16px !important;
  }
  .wcc-editor-content .size-18 {
    font-size: 18px !important;
  }
  .wcc-editor-content .size-21 {
    font-size: 21px !important;
  }
  .wcc-editor-content .size-24 {
    font-size: 24px !important;
  }
  .wcc-editor-content .size-28 {
    font-size: 28px !important;
  }
  .wcc-editor-content .size-32 {
    font-size: 32px !important;
  }
  .wcc-editor-content .size-35 {
    font-size: 35px !important;
  }
  .wcc-editor-content .size-38 {
    font-size: 38px !important;
  }
  .wcc-editor-content .size-42 {
    font-size: 42px !important;
  }
  .wcc-editor-content .size-46 {
    font-size: 46px !important;
  }
  .wcc-editor-content .size-48 {
    font-size: 48px !important;
  }
  .wcc-editor-content .size-50 {
    font-size: 50px !important;
  }
  .wcc-editor-content .size-54 {
    font-size: 54px !important;
  }
  .wcc-editor-content .size-60 {
    font-size: 60px !important;
  }
  .wcc-editor-content .size-64 {
    font-size: 64px !important;
  }
  .wcc-editor-content .size-68 {
    font-size: 68px !important;
  }
  .wcc-editor-content .size-72 {
    font-size: 72px !important;
  }
  .wcc-editor-content .size-76 {
    font-size: 73px !important;
  }
  .wcc-editor-content .size-80 {
    font-size: 74px !important;
  }
  .wcc-editor-content .size-84 {
    font-size: 75px !important;
  }
  .wcc-editor-content .size-88 {
    font-size: 76px !important;
  }
  .wcc-editor-content .size-92 {
    font-size: 77px !important;
  }
  .wcc-editor-content .size-96 {
    font-size: 78px !important;
  }
  .wcc-editor-content .size-100 {
    font-size: 79px !important;
  }
  .wcc-editor-content .size-104 {
    font-size: 80px !important;
  }
  .wcc-editor-content .size-108 {
    font-size: 81px !important;
  }
  .wcc-editor-content .size-112 {
    font-size: 82px !important;
  }
  .wcc-editor-content .size-116 {
    font-size: 84px !important;
  }
  .wcc-editor-content .size-120 {
    font-size: 85px !important;
  }
  .wcc-editor-content .size-124 {
    font-size: 86px !important;
  }
  .wcc-editor-content .size-128 {
    font-size: 87px !important;
  }
  .wcc-editor-content .size-132 {
    font-size: 88px !important;
  }
  .wcc-editor-content .size-136 {
    font-size: 89px !important;
  }
  .wcc-editor-content .size-140 {
    font-size: 90px !important;
  }
  .wcc-editor-content .size-144 {
    font-size: 91px !important;
  }
  .wcc-editor-content .size-148 {
    font-size: 92px !important;
  }
  .wcc-editor-content .size-152 {
    font-size: 93px !important;
  }
  .wcc-editor-content .size-156 {
    font-size: 94px !important;
  }
  .wcc-editor-content .size-160 {
    font-size: 95px !important;
  }
  .wcc-editor-content .size-164 {
    font-size: 96px !important;
  }
  .wcc-editor-content .size-168 {
    font-size: 97px !important;
  }
  .wcc-editor-content .size-172 {
    font-size: 98px !important;
  }
  .wcc-editor-content .size-176 {
    font-size: 99px !important;
  }
  .wcc-editor-content .size-180 {
    font-size: 100px !important;
  }
  .wcc-editor-content .size-184 {
    font-size: 111px !important;
  }
  .wcc-editor-content .size-188 {
    font-size: 112px !important;
  }
  .wcc-editor-content .size-192 {
    font-size: 113px !important;
  }
  .wcc-editor-content .size-196 {
    font-size: 114px !important;
  }
  .wcc-editor-content .size-200 {
    font-size: 115px !important;
  }
  .wcc-editor-content .size-204 {
    font-size: 116px !important;
  }
  .wcc-editor-content .size-208 {
    font-size: 117px !important;
  }
  .wcc-editor-content .size-212 {
    font-size: 118px !important;
  }
  .wcc-editor-content .size-216 {
    font-size: 119px !important;
  }
  .wcc-editor-content .size-220 {
    font-size: 120px !important;
  }
}
@media all and (max-width: 640px) {
  .wcc-editor-content .size-12 {
    font-size: 12px !important;
  }
  .wcc-editor-content .size-14 {
    font-size: 14px !important;
  }
  .wcc-editor-content .size-16 {
    font-size: 16px !important;
  }
  .wcc-editor-content .size-18 {
    font-size: 18px !important;
  }
  .wcc-editor-content .size-21 {
    font-size: 21px !important;
  }
  .wcc-editor-content .size-24 {
    font-size: 22px !important;
  }
  .wcc-editor-content .size-28 {
    font-size: 24px !important;
  }
  .wcc-editor-content .size-32 {
    font-size: 26px !important;
  }
  .wcc-editor-content .size-35 {
    font-size: 28px !important;
  }
  .wcc-editor-content .size-38 {
    font-size: 30px !important;
  }
  .wcc-editor-content .size-42 {
    font-size: 32px !important;
  }
  .wcc-editor-content .size-46 {
    font-size: 34px !important;
  }
  .wcc-editor-content .size-48 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-50 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-54 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-60 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-64 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-68 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-72 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-76 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-80 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-84 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-88 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-92 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-96 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-100 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-104 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-108 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-112 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-116 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-120 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-124 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-128 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-132 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-136 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-140 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-144 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-148 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-152 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-156 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-160 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-164 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-168 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-172 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-176 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-180 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-184 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-188 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-192 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-196 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-200 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-204 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-208 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-212 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-216 {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-220 {
    font-size: 36px !important;
  }
}
@media all and (max-width: 640px) {
  .wcc-editor-content .size-12 span {
    font-size: 12px !important;
  }
  .wcc-editor-content .size-14 span {
    font-size: 14px !important;
  }
  .wcc-editor-content .size-16 span {
    font-size: 16px !important;
  }
  .wcc-editor-content .size-18 span {
    font-size: 18px !important;
  }
  .wcc-editor-content .size-21 span {
    font-size: 21px !important;
  }
  .wcc-editor-content .size-24 span {
    font-size: 22px !important;
  }
  .wcc-editor-content .size-28 span {
    font-size: 24px !important;
  }
  .wcc-editor-content .size-32 span {
    font-size: 26px !important;
  }
  .wcc-editor-content .size-35 span {
    font-size: 28px !important;
  }
  .wcc-editor-content .size-38 span {
    font-size: 30px !important;
  }
  .wcc-editor-content .size-42 span {
    font-size: 32px !important;
  }
  .wcc-editor-content .size-46 span {
    font-size: 34px !important;
  }
  .wcc-editor-content .size-48 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-50 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-54 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-60 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-64 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-68 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-72 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-76 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-80 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-84 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-88 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-92 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-96 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-100 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-104 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-108 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-112 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-116 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-120 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-124 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-128 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-132 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-136 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-140 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-144 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-148 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-152 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-156 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-160 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-164 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-168 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-172 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-176 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-180 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-184 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-188 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-192 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-196 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-200 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-204 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-208 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-212 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-216 span {
    font-size: 36px !important;
  }
  .wcc-editor-content .size-220 span {
    font-size: 36px !important;
  }
}
