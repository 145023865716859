.postsWrapper {
  padding: 12px;
  border-radius: 6px;
  background-color: var(--user-post-bg);
}

@media (min-width: 768px) {
  .postsWrapper {
    padding: 20px;
  }
}