.cardWrapper {
  display: flex;
  flex-direction: column;
  padding: 8px 8px 0;
  margin-bottom: 5px;
  position: relative;
  background-color: var(--user-post-bg);
}

.moderatorsCardWrapper {
  background-color: var(--moderator-post-bg);
}

.cardWrapperPinsActivity {
  padding: 0;
  margin-bottom: 0;
  background-color: unset;
}

.cardWrapper:last-child {
  margin-bottom: 0;
}

.mainContent {
  flex: 1 1 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid var(--user-post-header);
  background: #fff;
}

.moderatorsCardWrapper .card {
  border: 2px solid var(--moderator-post-header);
}

.card:last-child {
  margin-bottom: 0;
}

.threadContent {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatarContainer {
  width: 36px;
  height: 36px;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid var(--avatar-border);
}

.avatarWithoutBorder {
  border: unset;
}

.headerModerator .avatarContainer {
  border-color: #fff;
}

.avatar {
  width: 28px;
  height: 28px;
}

.activityAvatar {
  background-color: var(--activity-avatar-bg);
}

.avatarIcon {
  color: var(--activity-icon-color);
}

.wrapper {
  flex-basis: 100%;
}

.header {
  background-color: var(--user-post-header);
  padding-bottom: 12px;
}

.userAndControlsWrapper {
  position: relative;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 12px;
  overflow: hidden;
}

.userTagsWrapper {
  padding: 0 12px;
}

.answerRequireRibbon::before {
  width: 100px;
  padding-bottom: 2px;
  position: absolute;
  content: 'Required';
  top: 12px;
  left: -30px;
  transform: rotate(-45deg);
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  background-color: var(--mandatory-border);
  z-index: 1;
  opacity: 0.9;
}

.headerModerator {
  background-color: var(--moderator-post-header);
}

.markAsReadBtn {
  padding: 0;
  line-height: 16px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
}

.userName {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.publishInfo {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dividerDot {
  font-weight: 300;
  font-size: 10px;
}

.published {
  display: block;
  font-size: 10px;
  line-height: 10px;
  font-weight: 300;
}

.userStatus {
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 700;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.absoluteLeftBottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.postImage {
  height: 120px;
  width: auto;
  max-width: 230px;
}

.postImage:hover {
  border-radius: 5px;
  transform: scale(0.95);
  transition:
    border-radius 0.3s ease,
    transform 0.3s ease;
  cursor: pointer;
}

.summary {
  border-radius: 6px;
  border: 1px solid var(--user-post-header);
}

.moderatorsCardWrapper .summary {
  border-color: var(--moderator-post-header);
}

.summaryHeader {
  padding: 2px 12px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  background-color: var(--user-post-header);
  border-radius: 5px 5px 0 0;
}

.moderatorsCardWrapper .summaryHeader {
  background-color: var(--moderator-post-header);
}

.summaryText {
  padding: 12px;
  color: var(--summary-text);
  border-radius: 0 0 5px 5px;
  background-color: var(--user-post-bg);
}

.moderatorsCardWrapper .summaryText {
  background-color: var(--moderator-post-bg);
}

.imageList {
  margin-bottom: -10px;
}

.commentList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.replySuggestionsWrapper {
  margin-bottom: 12px;
}

.editInput {
  flex: 1 1 100%;
}

.expandablePanels {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.commentsLoader {
  width: auto;
  height: auto;
  padding: 50px 85px;
}

@media (min-width: 768px) {
  .cardWrapper {
    padding: 20px 20px 0;
  }

  .cardWrapperPinsActivity {
    padding: 0;
  }

  .avatarContainer {
    width: 54px;
    height: 54px;
  }

  .avatar {
    height: 46px;
    width: 46px;
  }

  .userName {
    font-size: 18px;
    line-height: 20px;
  }

  .commentList {
    gap: 16px;
  }

  .content {
    gap: 20px;
  }

  .threadContent {
    padding: 14px 20px;
    gap: 20px;
  }

  .userAndControlsWrapper {
    padding: 20px 20px 12px;
  }

  .userTagsWrapper {
    padding: 0 20px;
  }

  .answerRequireRibbon::before {
    top: 13px;
    left: -28px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media (min-width: 768px) {
  .replySuggestionsWrapper {
    margin-bottom: 16px;
  }
}

@media (min-width: 1024px) {
  .rowLayoutWrapper {
    flex-direction: column;
  }

  .userAndControlsWrapper {
    padding: 12px;
  }

  .userTagsWrapper {
    padding: 0 12px;
  }

  .threadContent {
    padding: 12px;
  }

  .replySuggestionsWrapper {
    margin-bottom: 20px;
  }
}

@media (min-width: 1440px) {
  .cardWrapper {
    flex-direction: row;
    gap: 20px;
  }

  .expandablePanels {
    flex: 1 0 260px;
  }

  .rowLayoutWrapper {
    flex-direction: column;
    gap: 0;
  }

  .rowLayoutWrapper .expandablePanels {
    flex-basis: unset;
  }

  .userAndControlsWrapper {
    padding: 20px 20px 12px;
  }

  .userTagsWrapper {
    padding: 0 20px;
  }

  .threadContent {
    padding: 14px 20px;
  }
}
