.editHighlightModal {
  position: fixed;
  width: 100%;
  max-width: 572px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: var(--main-text-color);
}

.closeButton {
  padding: 7px;
  background-color: var(--user-post-bg);
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sectionTitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.originalText {
  padding: 12px;
  background-color: var(--user-post-bg);
  color: var(--summary-text);
}

.textField {
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  border: 1px solid var(--user-post-header);
}

.textInput {
  width: 100%;
  height: 64px;
  padding: 8px 6px;
  resize: none;
  font-family: inherit;
}

.textInput:focus {
  outline: none;
  border: 1px solid var(--button-green);
}

.colors {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 4px;
}

.editButton {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.editButtonIcon {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.modalControls {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.modalButton {
  width: 130px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .editHighlightModal {
    padding: 40px;
  }

  .header {
    margin-bottom: 8px;
  }

  .headerTitle {
    font-size: 28px;
    line-height: 34px;
  }
}
