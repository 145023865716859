.postCreatorBadge {
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: var(--header-transparent-bg);
  backdrop-filter: blur(2px);
  padding: 2px 10px 2px 2px;
  border-radius: 44px;
}

.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.userInfoWrapper {
  color: #fff;
}

.userName {
  font-size: 12px;
  font-weight: 500;
}

.date {
  font-size: 10px;
  font-weight: 400;
}