.noteCard {
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  border: 3px solid var(--user-post-header);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userInfo {
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userName {
  font-size: 10px;
  font-weight: 500;
  color: var(--main-text-color);
}

.time {
  font-size: 8px;
  font-weight: 500;
  color: var(--controls-grey);
}

.text {
  font-size: 12px;
}
