.topSection {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 20px 0;
  z-index: 2;
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.subHeader {
  display: flex;
  justify-content: space-between;
}

.tabs {
  display: none;
}

.toggleMenuButton {
  height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #eff0f5;
  color: #000;
}

.activityNameWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rowLayout .title,
.rowLayout .verticalDivider {
  display: none;
}

.title,
.activityName {
  font-size: 18px;
}

.rowLayout .activityName,
.title {
  font-weight: 600;
}

.verticalDivider {
  flex-shrink: 0;
  width: 2px;
  height: 16px;
  background-color: var(--main-text-color);
}

.activityName {
  font-weight: 300;
  color: var(--main-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topSectionActions {
  display: flex;
  gap: 12px;
}

.actionsWrapper {
  position: relative;
  margin-left: auto;
}

.addPostText {
  white-space: nowrap;
}

.card {
  padding: 14px;
  border-radius: 10px;
  border: 1px solid #d1d9e2;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.buttonGroup {
  position: relative;
  width: 50px;
}

.actionButton {
  width: 28px;
  height: 28px;
  background-color: #eff0f5;
  border-radius: 50%;
  color: #000;
  min-width: 28px;
}

.highlightColorBtn {
  width: 100%;
  height: 100%;
  padding-left: 30px;
  background-color: var(--user-post-bg);
  outline: none;
  border-radius: 44px;
}

.highlightColorBtnActive {
  background-color: var(--button-green);
}

.highlightColorBtn:hover {
  color: var(--controls-grey);
}

.arrowIconActive {
  color: #fff;
  transform: rotate(180deg);
}

.activateHighlightingBtn {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 2px;
  left: 2px;
  background-color: var(--user-post-bg);
  color: var(--btn-disabled);
  cursor: unset;
}

.activateHighlightingBtnActive {
  cursor: pointer;
}

.activateHighlightingIcon {
  width: 16px;
  height: 16px;
}

.activateHighlightingBtn:hover {
  background-color: var(--user-post-bg);
}

.buttonIcon {
  width: 16px;
  height: 16px;
}

.expandablePanelsIcon {
  transform: rotate(90deg);
}

.actionButton:hover,
.activeButton {
  background-color: var(--button-green);
  color: #fff;
}

.showDesktop {
  display: none;
}

.postModalWrapper {
  width: 100%;
  max-width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.postModalWrapper:focus-visible {
  outline: none;
}

.postModal {
  padding: 40px;
  background-color: #fff;
  border: 1px solid #eff0f5;
  border-radius: 15px;
}

.postAuthor {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.avatar {
  width: 60px;
  height: 60px;
}

.authorName {
  margin: 0;
}

.authorStatus {
  color: #258e61;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.errorMessage {
  color: #eb3b3b;
}

.closeModal {
  margin-left: auto;
}

.closeModalButton {
  padding: 10px;
}

.sectionSelectWrapper {
  position: relative;
}

.selectSectionBtn {
  min-width: 120px;
  display: flex;
  padding: 2px 12px 2px 2px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
  background-color: var(--button-green);
  outline: none;
  border-radius: 44px;
}

.sectionBtnWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  color: var(--button-green);
}

@media (min-width: 768px) {
  .topSection {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    margin-bottom: 12px;
  }

  .subHeader {
    gap: 20px;
  }

  .rowLayout {
    margin-bottom: 0;
  }

  .title,
  .activityName {
    font-size: 24px;
  }

  .toggleMenuButton {
    height: 50px;
    width: 50px;
    min-width: 50px;
  }

  .header {
    width: 50%;
    gap: 12px;
    margin-bottom: 0;
  }

  .verticalDivider {
    height: 22px;
  }

  .topSectionNav {
    display: flex;
    width: auto;
    justify-content: flex-end;
    gap: 20px;
  }

  .tab {
    height: 34px;
    min-height: 34px;
    margin-right: 15px;
  }

  .actionButton {
    width: 34px;
    height: 34px;
  }

  .buttonGroup {
    width: 55px;
  }

  .highlightColorBtn {
    display: flex;
    align-items: center;
    padding-left: 35px;
  }

  .activateHighlightingBtn {
    width: 30px;
    height: 30px;
  }

  .activateHighlightingIcon {
    width: 18px;
    height: 18px;
  }

  .buttonIcon {
    width: 20px;
    height: 20px;
  }

  .postModal {
    max-width: 600px;
  }

  .selectSectionBtn {
    min-width: 125px;
  }

  .iconWrapper {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
}

@media (min-width: 1024px) {
  .rowLayout {
    background-color: var(--user-post-bg);
  }

  .rowLayout .actionButton,
  .rowLayout .highlightColorBtn,
  .rowLayout .activateHighlightingBtn {
    background-color: #fff;
  }

  .rowLayout .activeButton,
  .rowLayout .highlightColorBtnActive,
  .rowLayout .actionButton:hover {
    background-color: var(--button-green);
  }

  .header {
    gap: 20px;
  }

  .subHeader {
    gap: 40px;
  }

  .topSectionActions {
    gap: 20px;
  }

  .title,
  .activityName {
    font-size: 28px;
  }

  .verticalDivider {
    height: 25px;
  }

  .rowLayout {
    padding: 60px 40px 40px;
    gap: 40px;
    flex-direction: column;
    align-items: unset;
  }

  .rowLayout .header {
    width: 100%;
  }

  .rowLayout .activityNameWrapper {
    width: 100%;
    justify-content: space-between;
  }

  .rowLayout .sectionSelectWrapper {
    display: none;
  }

  .rowLayout .tabs {
    display: flex;
    gap: 12px;
  }
}

@media (min-width: 1440px) {
  .topSection {
    gap: 20px;
  }

  .header {
    width: 430px;
    flex-shrink: 0;
  }

  .subHeader {
    flex: 1 1 100%;
  }

  .expandablePanelsIcon {
    transform: rotate(0);
  }

  .rowLayout .expandablePanelsIcon {
    transform: rotate(90deg);
  }

  .sectionSelectWrapper {
    display: none;
  }

  .tabs {
    display: flex;
    gap: 20px;
  }

  .toggleMenuButton {
    display: none;
  }

  .activityNameWrapper {
    width: 100%;
  }
}
