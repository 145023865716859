.avatarWrapper {
  position: relative;
  padding: 2px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
}

.personAvatar {
  border-radius: 50%;
}