.avatar,
.iconWrapper,
.iconImage {
  width: 40px;
  height: 40px;
}

.avatar {
  position: relative;
  border-radius: 6px;
  overflow: visible;
  background-color: unset;
}

.notifications {
  position: absolute;
  height: 16px;
  min-width: 16px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  background-color: var(--badge-red);
  top: -5px;
  right: -5px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--icon-default-bg);
}

.circleIcon {
  border-radius: 50%;
}

.icon {
  font-size: 22px;
}

.iconImage {
  border-radius: 6px;
}

@media (min-width: 768px) {
  .avatar,
  .iconWrapper,
  .iconImage {
    width: 50px;
    height: 50px;
  }

  .notifications {
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 12px;
  }
}