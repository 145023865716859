.wrapper {
  min-height: 100%;
  display: flex;
}

.main {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1 1 100%;
  align-self: stretch;
  background-color: #f9f9f9;
}

.content {
  flex: 1 1 100%;
  padding: 0 16px;
  max-width: 100%;
  width: 100%;
}

.innerWrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  background-color: #ffffff;
  max-width: 1440px;
}

.navigation {
  display: none;
}

@media (min-width: 768px) {
  .content {
    padding: 0 20px;
  }
}

@media (min-width: 1440px) {
  .navigation {
    display: flex;
  }
}
