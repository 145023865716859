.drawerTitle {
  font-weight: 600;
  margin: 5px 0 20px;
}

.container {
  display: flex;
  gap: 20px;
}

.listContainer {
  display: none;
  flex: 1 1 40%;
  min-width: 330px;
  top: 75px;
  z-index: 1;
}

.contentWrapper {
  flex: 1 1 100%;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 20px 0;
}

.title {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
}

.mobileBurger {
  padding: 10px;
}

@media (min-width: 768px) {
  .listContainer {
    min-width: 430px;
  }

  .drawerTitle {
    margin-bottom: 25px;
  }
}

@media (min-width: 1440px) {
  .listContainer {
    display: block;
  }
}
