.suggestionsContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.suggestionsGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 500px;
  gap: 12px;
}

.suggestionsGroupName {
  font-size: 15px;
  font-weight: 500;
  color: var(--controls-grey);
  margin-bottom: -6px;
  margin-right: 6px;
}

.suggestion {
  position: relative;
  padding: 12px 16px;
  margin-right: 10px;
  border: 1px dashed var(--reply-border-color);
  border-radius: 6px 0 6px 6px;
  background-color: #fff;
  color: var(--main-text-color);
}

.suggestion:after {
  content: '';
  border-bottom: 1px dashed var(--reply-border-color);
  border-right: 1px dashed var(--reply-border-color);
  background-color: #fff;
  position: absolute;
  right: -7px;
  top: 0;
  width: 11px;
  height: 6px;
  transform: rotate(300deg) skew(30deg, 10deg);
}

.suggestion:hover,
.suggestion:hover:after {
  background-color: var(--reply-body-color);
  border-color: var(--reply-body-color);
  border-style: solid;
  color: #fff;
  cursor: pointer;
}

.suggestionText {
  font-size: 15px;
  text-align: justify;
  color: inherit;
}

.footer {
  margin: -10px 0 4px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.footerButton {
  color: var(--button-green);
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  height: auto;
  display: flex;
  gap: 4px;
}

.footerButton:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.iconReplay {
  transform: rotate(300deg) scaleX(-1);
}

.iconHide {
  transform: rotate(90deg);
}
