.pinContainer {
  position: absolute;
}

.pinBtn {
  position: absolute;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  background-color: red;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  transition: border-width 0.1s;
  z-index: 1;
}

.pinActive,
.pinBtn:hover {
  z-index: 2;
  border-width: 3px;
}

@media (min-width: 768px) {
  .pinBtn {
    width: 24px;
    height: 24px;
    border-width: 3px;
  }

  .pinActive,
  .pinBtn:hover {
    border-width: 6px;
  }
}
