.themesWrapper {
  position: relative;
}

.themeBadge {
  background-color: var(--user-post-bg);
  border-radius: 15px;
  width: fit-content;
  padding: 2px 12px 2px 2px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 2px;
}

.activeBadge {
  background-color: var(--button-green);
  color: #fff;
}

.themeIconContainer {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--user-post-header);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--button-green);
}
