.videoPlayer {
  margin-bottom: 10px;
}

.postVideoTranscript {
  max-height: 300px;
}

.videoTranscriptCollapseBtn {
  margin: 10px 0 0 auto;
  color: var(--button-green);
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  height: auto;
  display: flex;
  gap: 4px;
}

.videoTranscriptCollapseBtn:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.iconHide {
  transform: rotate(-90deg);
}

.iconHideActive {
  transform: rotate(90deg);
}
