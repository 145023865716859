.tag {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: flex-start;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  background-image: linear-gradient(to right, rgba(255 255 255/60%), rgba(255 255 255/60%));
}

.dislikeIcon {
  transform: rotate(180deg);
}