.filterPostByTagPopup {
  width: 220px;
  padding: 12px 5px 12px 12px;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 4px 2px var(--popup-shadow);
  border-radius: 4px;
}

.popupTitle {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.tagList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 8px;
  max-height: 250px;
  overflow-y: scroll;
  scrollbar-width: thin;
}