.wrapper {
  width: fit-content;
}

.tab {
  width: 34px;
  height: 34px;
  background-color: var(--user-post-header);
  padding: 2px;
  border-radius: 44px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.tab:hover {
  background-color: var(--button-green);
}

.tabActive {
  background-color: var(--button-green);
  width: 100%;
  padding-right: 20px;
  transition: width 0.3s;
  cursor: default;
}

.tab:disabled {
  background-color: var(--btn-disabled);
  cursor: default;
}

.iconWrapper {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: var(--button-green);
  flex-shrink: 0;
}

.tab:disabled .iconWrapper {
  color: var(--btn-disabled);
}

.text {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}
