.mainPreloader {
  position: fixed;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 3;
  overflow: hidden;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 100px);
}

@media (min-width: 480px) {
  .container {
    width: 300px;
  }
}