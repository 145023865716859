.tagLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
}

.tagLabel:hover {
  cursor: pointer;
  background-color: var(--user-post-bg);
}

.tagNameWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tagName {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.customCheckbox {
  width: 20px;
  height: 20px;
  border: 1px solid var(--controls-grey);
  border-radius: 4px;
}

.checkbox {
  display: none;
}

.checkbox:checked ~ .customCheckbox {
  background-image: url('../../../../assets/icons/checkMark.svg');
  background-position: center;
  background-repeat: no-repeat;
}
