.selectSectionModal {
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 -1px 12px var(--popup-shadow);
  border-radius: 10px;
  z-index: 1;
}

.selectSectionBtn {
  display: flex;
  align-items: center;
  padding: 2px 15px 2px 2px;
  gap: 8px;
  border-radius: 16px;
  background-color: var(--user-post-bg);
}

.selectSectionBtn:disabled {
  cursor: default;
}

.selectSectionBtn:hover .iconWrapper {
  background-color: #fff;
}

.iconWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--user-post-bg);
  color: var(--button-green);
}

.title {
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  color: #000;
}

.selectSectionBtn:disabled .iconWrapper,
.selectSectionBtn:disabled .title {
  color: var(--btn-disabled);
}

.selectSectionBtn:disabled:hover .iconWrapper {
  background-color: unset;
}
