.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tabsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.tab {
  height: auto;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  color: var(--tagged-chat-btn);
}

.tab:hover,
.tabActive {
  color: var(--button-green);
  background-color: transparent;
}

.loader {
  width: auto;
  height: auto;
  padding: 50px 85px;
}

.errorMessage {
  color: #eb3b3b;
}
