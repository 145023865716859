.container {
  width: 100%;
  align-self: flex-end;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.firstComment {
  padding-top: 12px;
}

.lastComment {
  margin-bottom: 12px;
}

.chatContainer {
  gap: 12px;
}

.container.authorsComment {
  align-self: flex-start;
  flex-direction: row-reverse;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comment {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}

.chatComment {
  gap: 10px;
  margin-bottom: 0;
}

.authorsComment .body {
  align-items: flex-start;
}

.contentWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.chatComment .contentWrapper {
  flex-direction: row-reverse;
  width: auto;
  margin: 0;
}

.chatContainer .published {
  font-size: 10px;
  line-height: 12px;
  color: var(--controls-grey);
}

.mentionTo {
  color: var(--button-green);
}

.authorsComment .contentWrapper {
  flex-direction: row-reverse;
  margin: 0;
}

.controlsButton {
  width: 30px;
  height: 30px;
}

.controlsIcon {
  font-size: 24px;
  color: var(--controls-grey);
}

.newComment {
  animation: 1s linear highlightComment;
}

.newChatComment {
  animation: 1s linear highlightChatComment;
}

@keyframes highlightComment {
  from {
    background-color: rgba(37, 142, 97, 0.12);
  }

  to {
    background-color: rgba(255, 255, 255, 1);
  }
}

@keyframes highlightChatComment {
  from {
    background-color: rgba(37, 142, 97, 0.12);
  }

  to {
    background-color: var(--user-post-bg);
  }
}

.avatarSize {
  width: 22px;
  height: 22px;
}

.chatAvatarSize {
  width: 26px;
  height: 26px;
}

.chatContainer .avatar {
  width: 30px;
  height: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px;
  background-color: var(--user-post-bg);
  border-radius: 6px 0 6px 6px;
  flex: 1 1 100%;
  position: relative;
}

.filesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.authorsComment .content {
  border-radius: 0 6px 6px 6px;
}

.authorsComment .chatContent {
  border-radius: 15px;
}

.content::after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 8px 8px 0 0;
  border-color: var(--user-post-bg) transparent transparent transparent;
  top: 0;
  right: -8px;
}

.chatContent {
  padding: 8px 12px;
  border-radius: 15px;
  background-color: var(--chat-content);
}

.chatContent::after {
  display: none;
}

.contentTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.authorsComment .content::after {
  right: auto;
  left: -8px;
  border-width: 0 8px 8px 0;
  border-color: transparent var(--user-post-bg) transparent transparent;
}

.info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 9px;
  line-height: 11px;
  color: var(--controls-grey);
}

.commentInfo {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.authorsComment .commentInfo {
  flex-direction: row;
}

.chatContainer .commentInfo {
  flex-direction: row;
}

.chatContainer .commentAuthorName {
  margin-left: 40px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.chatContainer .mentionTo {
  font-size: 10px;
  line-height: 12px;
}

.commentAuthorName {
  margin: 0;
}

.text {
  word-break: break-word;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 14px;
}

.attachmentsContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.attachedImages {
  width: 100%;
}

.commentImage {
  height: 100px;
  width: auto;
  max-width: 160px;
}

.commentImage:hover {
  border-radius: 5px;
  transform: scale(0.95);
  transition:
    border-radius 0.3s ease,
    transform 0.3s ease;
  cursor: pointer;
}

.attachmentWrapper {
  display: block;
  width: fit-content;
  text-decoration: none;
  color: inherit;
}

.attachmentAudio {
  max-width: 300px;
}

.videoAttachmentWrapper {
  max-width: 500px;
}

.audioAttachmentWrapper {
  display: flex;
}

.attachmentAudio {
  flex: 1 1 100%;
  width: 0;
}

.editContainer {
  align-items: center;
  padding-top: 12px;
  margin-bottom: 12px;
  gap: 12px;
}

.editContainerTop {
  display: flex;
  gap: 12px;
  align-items: center;
}

.editForm {
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: #f8f9fb;
  border-radius: 5px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.input {
  flex: 1 0 100%;
  color: #5f6d7e;
  background: transparent;
  font-size: 14px;
  line-height: 22px;
}

.input fieldset {
  border: none;
}

.input:focus-within fieldset {
  border: 2px solid var(--button-green);
}

.input:focus-visible {
  outline: none;
}

.attachFileButton {
  padding: 12px;
  transform: rotate(45deg);
}

.fileInput {
  display: none;
}

.editButton {
  height: 40px;
  margin-right: 5px;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: var(--button-green);
  font-weight: 700;
}

.editAttachment {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 300px;
  min-width: 50px;
  height: 150px;
}

.editAttachmentImage {
  object-position: center;
  object-fit: cover;
  height: auto;
  border-radius: 5px;
}

.editAttachmentRemoveButton {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #ccc;
}

.editAttachmentRemoveButton:hover {
  color: #fff;
}

.attachmentLoading {
  align-self: stretch;
  width: 200px;
  background-color: #f8f9fb;
  border-radius: 5px;
}

.attachmentFiller {
  border-radius: 5px;
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 10px;
  background-color: #fff;
}

.attachmentFiller:hover {
  color: var(--secondary-text-color);
}

.attachmentIcon {
  font-size: 30px;
}

.attachmentName {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachmentNameAudio {
  margin: 0;
  font-size: 12px;
}

.editSendButton {
  padding: 10px;
}

.controlsPopoverContent {
  position: relative;
  display: flex;
  flex-direction: column;
}

.decorativeTriangle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
}

.triangleLeft {
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
}

.triangleRight {
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
}

.controlsPopoverButton {
  height: auto;
  padding: 8px;
  gap: 4px;
  justify-content: flex-start;
  border-radius: 0;
}

.controlsPopoverButton:hover {
  background-color: var(--green-transparent-bg);
}

.closeEditButton {
  justify-content: center;
}

.controlsPopoverItemText {
  color: var(--main-text-color);
}

@media (min-width: 380px) {
  .commentImage {
    height: 130px;
    max-width: 220px;
  }
}

@media (min-width: 480px) {
  .commentImage {
    max-width: 320px;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 12px;
  }

  .firstComment {
    border-top: 2px dashed var(--button-green);
    padding-top: 16px;
  }

  .lastComment {
    margin-bottom: 16px;
  }

  .contentWrapper {
    gap: 10px;
  }

  .avatarSize {
    width: 30px;
    height: 30px;
  }

  .content {
    padding: 12px 16px;
  }

  .chatContent {
    padding: 6px 12px;
  }

  .text {
    font-size: 15px;
    line-height: 18px;
  }

  .commentImage {
    height: 150px;
    max-width: 500px;
  }

  .editForm {
    flex-wrap: nowrap;
  }

  .input {
    flex: auto;
  }
}

@media (min-width: 1024px) {
  .firstComment {
    padding-top: 20px;
  }

  .lastComment {
    margin-bottom: 20px;
  }
}
