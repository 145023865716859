.postControls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.controlsButton {
  width: 34px;
  height: 34px;
  background-color: var(--user-post-bg);
  box-shadow: 0 1px 2px 0 var(--popup-shadow);
}

.controlsButton:hover {
  background-color: #fff;
}

.pinnedBtn {
  background: var(--button-green);
}

.pinnedBtт:hover {
  color: #000;
  background-color: #fff;
}

.controlsIcon {
  font-size: 20px;
  color: #000;
}

.pinnedIcon {
  color: #fff;
}

.pinnedBtn:hover .pinnedIcon {
  color: #000;
}

.controlsPopoverContent {
  position: relative;
  padding: 4px;
  display: flex;
  flex-direction: column;
}

.controlsPopoverContent::after {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
}

.controlsPopoverButton {
  height: auto;
  padding: 4px;
  gap: 4px;
  justify-content: flex-start;
}

.controlsPopoverButton:hover {
  background-color: var(--green-transparent-bg);
}

.controlsPopoverItemText {
  color: var(--main-text-color);
}

@media (min-width: 768px) {
  .postControls {
    gap: 10px;
  }
}
