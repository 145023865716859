.instructions {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: -40px;
}

.instructionImage {
  width: 100%;
}