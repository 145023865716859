.word {
  position: relative;
  padding: 0 2px;
  user-select: text;
}

.wordPassed {
  color: var(--button-green);
}

.currentWord:after {
  content: '';
  position: absolute;
  left: 2px;
  bottom: 0;
  height: 2px;
  width: calc(100% - 4px);
  margin: 0 auto;
  border-bottom: 2px dotted var(--button-green);
}

.highlightedWord {
  color: #fff;
  background-color: red;
}