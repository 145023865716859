.container {
  padding: 8px;
  border-radius: 10px;
  color: #5b5858;
  background-color: #fff;
  cursor: pointer;
}

.container:hover {
  box-shadow: 0 0 12px var(--button-green);
}

.selectedUser {
  outline: 3px solid var(--button-green);
  box-shadow: 0 0 12px var(--button-green);
}

.userCard {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.userCardHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatarWrapper {
  position: relative;
}

.avatarSize {
  width: 28px;
  height: 28px;
}

.numberInCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  line-height: 0;
}

.unreadPosts {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--badge-red);
  border: 2px solid #fff;
}

.completedActivities,
.uncompletedActivities {
  border: 2px solid var(--user-post-bg);
}

.completedActivities {
  background-color: var(--button-green);
}

.uncompletedActivities {
  background-color: var(--badge-red);
}

.userInfoWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.userInfoHeader {
  display: flex;
  align-items: center;
  gap: 6px;
}

.userName {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--main-text-color);
}

.userStats {
  display: flex;
  gap: 4px;
}

.time,
.noData {
  max-width: 150px;
  display: flex;
  gap: 2px;
  font-size: 10px;
  color: var(--text-disabled);
}

.engagementTime {
  white-space: nowrap;
}

.lastPostTime {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userOverviewBtn {
  width: 34px;
  height: 34px;
  background-color: var(--user-post-bg);
}

.postsCount {
  margin-top: 8px;
  float: right;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.postsCountNumber {
  font-weight: 600;
}

@media (min-width: 768px) {
  .container {
    padding: 12px;
  }

  .avatarSize {
    width: 42px;
    height: 42px;
  }

  .unreadPosts {
    top: -5px;
    right: -5px;
  }

  .userName {
    font-size: 18px;
    line-height: 22px;
  }

  .time {
    max-width: 300px;
    font-size: 12px;
  }
}