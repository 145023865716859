.heatmapRowLayout {
  padding: 20px;
}

.heatmapImage {
  display: block;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
}

.pinsImageToggleBtn {
  margin: 0 0 12px auto;
  color: var(--button-green);
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  height: auto;
  display: flex;
  gap: 4px;
}

.pinsImageToggleBtn:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.iconHide {
  transform: rotate(-90deg);
}

.iconHideActive {
  transform: rotate(90deg);
}