.list {
  width: 290px;
}

.layoutContainer {
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.layoutContainer:hover {
  box-shadow: 0 0 12px var(--button-green);
}

.container {
  width: 100%;
  position: relative;
  flex-shrink: 0;
  letter-spacing: -0.1px;
}

.darkBackground {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0;
  background-color: var(--header-transparent-bg);
}

.activityImage {
  border-radius: 5px;
  vertical-align: middle;
}

.layoutSmall {
  padding: 4px 8px 4px 4px;
}

.layoutSmall .container {
  display: flex;
  gap: 8px;
}

.layoutSmall .notifications,
.layoutMedium .notifications,
.layoutLarge .notifications {
  height: 20px;
  min-width: 20px;
}

.layoutSmall .info {
  padding-top: 10px;
}

.layoutSmall .activityImage {
  width: 85px;
  height: 85px;
}

.layoutSmall .description {
  max-width: 100px;
}

.layoutSmall .statusesContainer {
  gap: 12px;
}

.layoutMedium {
  padding: 4px;
}

.layoutMedium .infoContainer {
  margin: 0 4px;
}

.layoutSmall .status {
  padding-left: 12px;
}

.layoutMedium .activityImage {
  width: 100%;
  height: 120px;
  margin-bottom: 12px;
  border-radius: 5px 5px 0 0;
}

.layoutMedium .title,
.layoutMedium .additional {
  font-size: 12px;
  line-height: 14px;
}

.layoutLarge {
  padding: 4px;
}

.layoutLarge .container {
  position: relative;
  height: 190px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.layoutLarge .title,
.layoutLarge .description,
.layoutLarge .posts {
  color: #fff;
}

.layoutLarge .activityOverviewBtn,
.layoutLarge .text,
.layoutLarge .posts,
.layoutLarge .statusesContainer {
  position: absolute;
}

.layoutLarge .activityOverviewBtn {
  top: 10px;
  right: 12px;
  background-color: #fff;
}

.layoutLarge .activityOverviewBtn:hover {
  opacity: 0.8;
}

.layoutLarge .text {
  left: 10px;
  bottom: 12px;
}

.layoutLarge .posts {
  right: 10px;
  bottom: 12px;
}

.layoutLarge .statusesContainer {
  top: 8px;
  left: 8px;
  gap: 8px;
}

.layoutLarge .status {
  padding: 4px 4px 4px 18px;
  background-color: #fff;
  border-radius: 12px;
}

.layoutLarge .status::before {
  top: 5px;
  left: 4px;
}

.layoutLarge .title {
  font-size: 14px;
  line-height: 15px;
}

.selected {
  outline: 3px solid var(--button-green);
  box-shadow: 0 0 12px var(--button-green);
}

.rowLayoutContainer.selected {
  outline: 5px solid var(--button-green);
  box-shadow: 0 0 12px var(--button-green);
}

.infoContainer {
  flex-grow: 1;
}

.info {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  margin-bottom: 15px;
}

.notifications {
  position: absolute;
  height: 16px;
  min-width: 16px;
  padding: 2px;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  background-color: var(--badge-red);
  top: -5px;
  right: -5px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
}

.text {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.title {
  color: #1c1616;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.activityOverviewBtn {
  width: 34px;
  height: 34px;
  background-color: var(--user-post-bg);
}

.description {
  max-width: 150px;
  color: #5b5858;
  font-size: 9px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.additional {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 12px;
}

.statusesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.status {
  color: #1c1616;
  font-weight: 500;
  position: relative;
  padding-left: 16px;
  text-transform: uppercase;
}

.status::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.statusPrivate::before {
  background: #e22929;
}

.statusOpeningSoon::before {
  background: #f1c733;
}

.statusOpen::before {
  background: #41c22c;
}

.statusClosed::before {
  background: #e22929;
}

.posts {
  color: var(--secondary-text-color);
  font-weight: 600;
}

.postsValue {
  font-weight: 500;
}

.group {
  background: #eff0f5;
  padding: 8px;
  border-radius: 10px;
}

.group:not(:last-child) {
  margin-bottom: 16px;
}

.groupHeader {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.groupHeader:hover {
  color: var(--button-green);
}

.groupHeader:not(.groupHeaderOpened) {
  border-radius: 8px;
}

.groupHeaderText {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.groupHeaderIcon {
  font-size: 12px;
  margin-left: 8px;
  transition: all 0.3s;
}

.groupHeaderOpened .groupHeaderIcon {
  transform: rotateZ(90deg);
}

.groupList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 12px;
}

.footer {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.footerWrp {
  display: none;
}

.activityImageWrapper {
  position: relative;
}

@media (min-width: 480px) {
  .footerWrp {
    display: flex;
  }
}

@media (min-width: 768px) {
  .group {
    padding: 12px;
  }

  .list {
    width: 370px;
  }

  .layoutContainer {
    padding: 12px;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
  }

  .description {
    max-width: 185px;
    font-size: 11px;
    line-height: 12px;
  }

  .additional {
    font-size: 12px;
    line-height: 14px;
  }

  .statusesContainer {
    gap: 20px;
  }

  .status::before {
    width: 12px;
    height: 12px;
  }

  .layoutSmall .container {
    gap: 16px;
  }

  .layoutSmall .activityImage {
    width: 100px;
    height: 100px;
  }

  .layoutSmall .description {
    max-width: 120px;
  }

  .layoutSmall .status {
    padding-left: 16px;
  }

  .layoutSmall .info {
    margin-bottom: 20px;
  }

  .layoutSmall .notifications,
  .layoutMedium .notifications,
  .layoutLarge .notifications {
    height: 25px;
    min-width: 25px;
    line-height: 16px;
  }

  .layoutMedium {
    padding: 8px;
  }

  .layoutMedium .activityImage {
    height: 155px;
    margin-bottom: 16px;
  }

  .layoutMedium .title,
  .layoutLarge .title {
    font-size: 16px;
    line-height: 20px;
  }

  .layoutLarge {
    padding: 8px;
  }

  .layoutLarge .container {
    height: 240px;
  }

  .layoutLarge .statusesContainer {
    top: 12px;
    left: 12px;
    gap: 12px;
  }

  .layoutLarge .activityOverviewBtn {
    top: 12px;
    right: 14px;
  }

  .layoutLarge .status {
    padding: 4px 6px 4px 22px;
  }

  .layoutLarge .status::before {
    left: 6px;
  }

  .notifications {
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    line-height: 12px;
  }
}

@media (min-width: 1024px) {
  .rowList {
    width: 100%;
    max-height: unset;
    overflow-y: hidden;
    padding-bottom: 20px;
  }

  .rowLayoutGroup {
    padding: 20px 20px 0;
    background: #fff;
  }

  .rowLayoutGroup:not(:last-child) {
    margin-bottom: 0;
  }

  .rowLayoutGroup .groupList {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
    row-gap: 20px;
  }

  .activityWrapper {
    box-sizing: border-box;
    width: 50%;
    padding: 0 10px;
  }

  .rowLayoutContainer {
    width: 100%;
    background: var(--user-post-bg);
  }

  .rowLayoutContainer .container {
    height: 280px;
  }

  .groupTitle {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: var(--group-title-text);
  }

  .layoutLarge .activityOverviewBtn {
    top: 16px;
    right: 16px;
  }
}

@media (min-width: 1280px) {
  .activityWrapper {
    width: 33.3%;
  }
}

@media (min-width: 1440px) {
  .list {
    width: 430px;
    position: sticky;
    top: 75px;
    z-index: 1;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rowList {
    max-height: unset;
    width: 100%;
    overflow-y: hidden;
  }

  .layoutSmall .container {
    gap: 12px;
  }

  .layoutSmall .activityImage {
    width: 140px;
    height: 140px;
  }

  .layoutSmall .info {
    margin-bottom: 60px;
  }

  .layoutMedium .activityImage {
    height: 180px;
    margin-bottom: 20px;
  }

  .layoutMedium .infoContainer {
    margin: 0 8px;
  }

  .layoutMedium .additional {
    margin-bottom: 12px;
  }

  .layoutLarge .container {
    height: 280px;
  }

  .notifications {
    min-width: 20px;
    height: 20px;
  }
}
