.videosWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
  background-color: var(--post-list-bg);
  border-radius: 8px;
}

.rowLayout {
  background-color: #fff;
}

.videoWithTranscript {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.layoutWrapper {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  overflow: hidden;
}

.playerWrapper {
  position: relative;
  aspect-ratio: 16 / 9;
}

.transcriptWrapper {
  padding-left: 10px;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 6px;
}

.thumbnailImage {
  position: absolute;
}

.summaryWrapper {
  position: absolute;
  bottom: 4px;
  padding: 0 4px;
}

.summary {
  width: 100%;
  font-size: 9px;
  color: #fff;
  border-radius: 6px;
  padding: 4px;
  background-color: var(--btn-shadow);
}

.backgroundRed {
  background-color: red;
  overflow-y: scroll;
}

@media(min-width: 768px) {
  .videoWithTranscript {
    flex-direction: row;
    gap: 0;
  }

  .videosWrapper {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
    padding: 10px;
  }

  .layoutWrapper {
    width: 50%;
    padding: 10px;
  }
  .summary {
    padding: 8px;
    font-weight: 500;
  }
}

@media(min-width: 1024px) {
  .summary {
    font-size: 10px;
  }
}