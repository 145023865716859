.questionScalesList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (min-width: 768px) {
  .questionScalesList {
    gap: 20px;
  }
}
