.headerRowLayout {
  padding: 20px;
}

.mainPinsImageContainer {
  position: relative;
}

.mainPinsImage {
  display: block;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  cursor: crosshair;
}

.mainPinsImagePending {
  cursor: wait;
}

.hide {
  display: none;
}

.pinsImageToggleBtn {
  margin: 0 0 12px auto;
  color: var(--button-green);
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  height: auto;
  display: flex;
  gap: 4px;
}

.headerRowLayout .pinsImageToggleBtn {
  margin-bottom: 0;
}

.pinsImageToggleBtn:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.iconHide {
  transform: rotate(-90deg);
}

.iconHideActive {
  transform: rotate(90deg);
}

.selectRectangle {
  position: absolute;
  border: 2px dashed var(--badge-red);
}

.resetFilteringButton {
  position: absolute;
  top: -20px;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--badge-red);
  background-color: #fff;
  z-index: 1;
}

.resetFilteringButton:hover {
  background-color: var(--people-controls);
}
