.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: 100%;
  padding-top: 50px;
  background-color: #fafafa;
}

.errorContainer img {
  width: 600px;
}

.homePageLink {
  font-size: 30px;
  color: var(--primary-color);
  text-decoration: none;
}
