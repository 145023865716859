.post {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  background-color: #fff;
}

.postContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatarWrapper {
  position: relative;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid var(--avatar-border);
}

.userAvatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.userInfo,
.mainContent {
  text-align: left;
}

.userName {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: var(--main-text-color);
}

.postCreationDate {
  font-size: 10px;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.postText {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.pinImageContainer {
  position: relative;
  height: 200px;
  border-radius: 8px;
}

.pinDot {
  position: absolute;
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.postControlsWrapper {
  position: absolute;
  top: 8px;
  right: 8px;
}

.commentsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.comments {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.horizontalDivider {
  margin-top: 12px;
  border-top: 2px dashed var(--button-green);
}

.startChatWrapper {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 12px 0;
}

.startChatBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  line-height: 14px;
  color: var(--tagged-chat-btn);
}

.startChatBtn:hover {
  color: var(--controls-grey);
}

.messagesNumber {
  display: grid;
  place-items: center;
  height: 16px;
  min-width: 16px;
  padding: 0 2px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  color: #fff;
  background-color: var(--button-green);
  border-radius: 50%;
}

@media (min-width: 768px) {
  .post {
    padding: 20px;
  }

  .postContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .postText {
    font-size: 20px;
    line-height: 24px;
  }

  .postInfo,
  .pinImageContainer {
    flex: 1 1 50%;
  }

  .pinImageContainer {
    height: 300px;
  }

  .userAvatar {
    width: 44px;
    height: 44px;
  }

  .userName {
    font-size: 18px;
    line-height: 20px;
  }

  .commentsContainer {
    gap: 20px;
  }

  .comments {
    gap: 20px;
  }

  .horizontalDivider {
    margin-top: 20px;
  }

  .postCreationDate {
    font-size: 11px;
  }

  .pinDot {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 1024px) {
  .postControlsWrapper {
    top: 12px;
    right: 12px;
  }
}