.colorButton {
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
}

.colorButton:hover {
  background-color: var(--user-post-bg);
}

.colorButtonWithoutTitle {
  width: fit-content;
  height: fit-content;
}

.colorBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  border-radius: 4px;
  outline: 2px solid transparent;
  color: #fff;
}

.colorBox:hover {
  opacity: 0.9;
}

.colorBoxActive {
  border: 2px solid #fff;
}

.colorTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
