.highlightCard {
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  border: 3px solid var(--user-post-header);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.userAndThemesWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user {
  display: flex;
  align-items: center;
  gap: 8px;
}

.imageSize {
  width: 26px;
  height: 26px;
}

.userName {
  font-size: 12px;
  font-weight: 500;
  color: var(--main-text-color);
  white-space: nowrap;
}

.timeWrapper {
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: 8px;
}

.time {
  font-size: 10px;
  font-weight: 500;
  color: var(--controls-grey);
}

.popoverContent {
  position: relative;
  padding: 4px;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.popoverContent:after {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
}

.popoverButton {
  height: auto;
  padding: 4px;
  border-radius: 4px;
  gap: 4px;
  justify-content: flex-start;
  color: var(--main-text-color);
  font-weight: normal;
  font-size: 14px;
}

.popoverButton:hover {
  background-color: var(--green-transparent-bg);
}

.popoverIcon {
  color: var(--button-green);
}

.text {
  font-size: 18px;
}

.textSmall {
  font-size: 12px;
}

.note {
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  background-color: var(--user-post-bg);
}

.noteSmall {
  font-size: 10px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 4px;
}
