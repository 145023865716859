.sentenceWrapper {
  display: flex;
  gap: 10px;
}

.sentence {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.sentenceWrapper:first-child .videosSectionSentence {
  margin-top: 10px;
}

.sentenceWrapper:last-child .videosSectionSentence {
  margin-bottom: 10px;
}

.sentenceWrapper:not(:first-child) .sentence {
  padding-top: 20px;
}

.sentenceControls {
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
}

.playSentenceBtn {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--button-green);
  flex-shrink: 0;
  color: var(--button-green);
}

.playSentenceBtn svg {
  width: 16px;
  height: 16px;
}

.playSentenceBtn:hover,
.playSentenceBtnActive {
  color: #fff;
  background-color: var(--button-green);
}

.sentenceStartTime {
  min-width: 40px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #000;
}

.sentenceTextWrapper {
  flex-grow: 1;
}

.sentenceScale {
  position: relative;
  width: 4px;
  background-color: var(--user-post-bg);
  margin-right: 10px;
  flex-shrink: 0;
}

.sentenceScale:before {
  content: '';
  position: absolute;
  width: 4px;
  height: 0;
  background-color: var(--button-green);
  transition: height linear 0.3s;
}

.sentenceScaleActive:before {
  height: 100%;
}

.sentenceText {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  background-color: var(--user-post-bg);
  border-radius: 6px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .sentence {
    gap: 10px;
  }

  .sentenceStartTime {
    font-size: 14px;
    line-height: 14px;
  }
}
