.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.popupWrapper {
  position: relative;
}

.selectedTags {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 6px;
}

.selectedTag {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px 2px 2px 4px;
  background-color: var(--user-post-bg);
  font-size: 10px;
  border-radius: 6px;
}

.removeSelectedTagBtn,
.removeSelectedTagBtn svg {
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.removeSelectedTagBtn {
  color: var(--controls-grey);
}

.removeSelectedTagBtn:hover {
  color: var(--badge-red);
}

.filterPostButton {
  width: 30px;
  height: 30px;
  background-color: var(--user-post-bg);
  box-shadow: 0 1px 2px 0 var(--popup-shadow);
}

.filterPostButton:hover {
  background-color: #fff;
}

.filterPostButton svg {
  color: var(--main-text-color);
}


.filterPostButtonActive,
.filterPostButtonActive:hover {
  background-color: var(--button-green);
}

.filterPostButtonActive:hover {
  opacity: 0.9;
}

.filterPostButtonActive svg {
  color: #fff;
}