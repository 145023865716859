.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: var(--main-text-color);
}

.description {
  font-size: 18px;
}

.features {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin-top: 20px;
}

.feature {
  font-size: 18px;
  font-weight: 500;
  color: var(--summary-text);
  display: flex;
  align-items: center;
  gap: 12px;
}

.feature:before {
  content: '';
  display: inline-block;
  flex-shrink: 0;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--controls-grey);
}

.featurePositive:before {
  content: '+';
  background-color: var(--button-green);
}

.featureNegative:before {
  content: '—';
  background-color: var(--badge-red);
}
