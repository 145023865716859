.statistics {
  position: absolute;
  right: 0;
  bottom: 40px;
  display: none;
  flex-wrap: wrap;
  gap: 20px;
  padding-right: 50px;
}

.statisticsCard {
  color: #fff;
}

.flexWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.action {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

.actionNumber {
  font-size: 58px;
  line-height: 58px;
  font-weight: 700;
}

.actionInfo {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.people {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.layout2 {
  padding: 15px 100px 10px 15px;
  background-color: var(--header-transparent-bg);
  border-radius: 10px 0 0 10px;
}

.layout2 .flexWrapper {
  align-items: center;
}

.layout3 {
  bottom: 20px;
  max-width: 300px;
  background-color: unset;
  margin-right: 20px;
  padding-right: 0;
}

.layout3 .statisticsCard {
  width: 140px;
  background-color: var(--header-transparent-bg);
  border-radius: 10px;
}

.layout3 .actionNumber {
  text-align: center;
}

.layout3 .actionInfo,
.layout4 .actionInfo,
.layout3 .people,
.layout4 .people {
  white-space: nowrap;
  font-size: 9px;
  line-height: 10px;
}

.layout3 .people,
.layout4 .people {
  font-weight: 500;
}

.layout3 .actionInfo {
  flex-direction: row;
  align-items: center;
  gap: 2px;
  margin-bottom: 12px;
}

.layout3 .action {
  margin-bottom: 15px;
  padding: 6px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 10px 10px 0 0;
}

.layout3 .flexWrapper {
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.layout3 .headerGreen {
  background-color: var(--button-green);
}

.layout3 .headerOrange {
  background-color: var(--statistic-header-orange);
}

.layout3 .headerBlue {
  background-color: var(--statistic-header-blue);
}

.layout3 .headerPurple {
  background-color: var(--statistic-header-purple);
}

.layout4 {
  padding-right: 40px;
  flex-direction: column;
  gap: 14px;
  bottom: 20px;
  right: 0;
}

.layout4 .statisticsCard {
  width: 280px;
  display: flex;
  gap: 8px;
  background-color: var(--header-transparent-bg);
  border-radius: 10px;
}

.layout4 .statisticsHeader {
  display: grid;
  place-content: center;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.layout4 .headerIconGreen {
  background-color: var(--button-green);
  background-image: url('@/assets/icons/add-user.svg');
}

.layout4 .headerIconOrange {
  background-color: var(--statistic-header-orange);
  background-image: url('@/assets/icons/puzzle.svg');
}

.layout4 .headerIconBlue {
  background-color: var(--statistic-header-blue);
  background-image: url('@/assets/icons/power-button.svg');
}

.layout4 .headerIconPurple {
  background-color: var(--statistic-header-purple);
  background-image: url('@/assets/icons/checked.svg');
}

.layout4 .statisticInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.layout4 .action,
.layout4 .actionNumber {
  font-size: 24px;
  line-height: 28px;
}

.layout4 .action {
  font-weight: 500;
  margin-bottom: 0;
}

.layout4 .flexWrapper {
  position: relative;
}

.layout4 .actionInfo {
  align-items: center;
  flex-direction: row;
  gap: 2px;
  position: absolute;
  top: 30px;
  right: 0;
}

.layout4 .actionInfo,
.layout4 .people {
  white-space: nowrap;
}

@media (min-width: 768px) {
  .statistics {
    display: flex;
  }
}
