.threadForm {
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form {
  display: flex;
  flex: 1 1 100%;
  padding: 0 4px;
  background-color: var(--user-post-bg);
  border-radius: 5px;
  flex-wrap: wrap;
}

.focusedForm {
  padding-bottom: 4px;
}

.textAreaWrapper {
  position: relative;
  width: 100%;
  display: flex;
}

.chatForm {
  padding: 0;
  border-radius: 15px;
}

.chatForm .formControls {
  margin-right: 10px;
}

.chatForm .textAreaWrapper {
  border-radius: 15px;
  background-color: var(--chat-content);
}

.chatForm .tooltips {
  gap: 12px;
}

.chatForm .commentFormButton {
  min-width: 16px;
  width: 16px;
  height: 16px;
}

.chatForm .commentFormButton svg {
  width: 16px;
  height: 16px;
}

.horizontalLine {
  width: 100%;
  margin: 0 0 5px;
  color: #fff;
  border: 1px solid #fff;
}

.tooltips {
  display: flex;
  gap: 8px;
}

.input {
  position: relative;
  flex: 1 1 100%;
  padding: 15px 40px 15px 10px;
  color: var(--people-input);
  background: transparent;
  border: none;
  resize: none;
  font-size: 15px;
  font-family: inherit;
}

.chatForm .input {
  min-height: 30px;
  padding: 7px 40px 7px 15px;
}

.formBottomBar {
  display: flex;
  width: 100%;
  gap: 12px;
  padding-left: 10px;
  justify-content: space-between;
  align-items: center;
}

.chatForm .formBottomBar {
  padding: 6px 0;
}

.hidden {
  display: none;
}

.formControls {
  position: relative;
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: 12px;
}

.show {
  display: flex;
}

.input:focus-visible {
  outline: none;
}

.formButton {
  height: 32px;
  padding: 0 15px;
  border-radius: 5px;
  font-weight: 700;
}

.mentionPopoverContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mentionItem {
  display: flex;
  padding: 10px;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.mentionModerators {
  padding: 14px 10px;
}

.mentionItem:hover {
  background-color: var(--user-post-bg);
}

.responseOptionsPopover {
  display: flex;
  flex-direction: column;
  box-shadow: 0 -2px 4px var(--popup-shadow);
}

.responseLabel {
  position: relative;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
}

.responseLabel:hover {
  background-color: var(--user-post-bg);
}

.radioInput:checked + .responseLabel {
  color: var(--button-green);
}

.mentionName {
  color: var(--main-text-color);
  font-weight: 500;
}

.mentionRole {
  color: var(--secondary-text-color);
  font-size: 12px;
}

.fileInput {
  display: none;
}

.submitButton {
  background-color: var(--button-green);
  font-weight: 700;
  font-size: 15px;
}

.mention,
.response {
  font-size: 13px;
  line-height: 15px;
  color: var(--controls-grey);
}

.response,
.mentionUser {
  font-weight: 600;
}

.chatForm .mention {
  font-size: 10px;
}

.responseOptions {
  color: var(--button-green);
  cursor: pointer;
  border-bottom: 1px solid var(--button-green);
}

.commentFormButton {
  width: 20px;
  height: 20px;
  min-width: 20px;
  padding: 0;
  color: #000;
}

.chatForm .commentFormButton {
  color: var(--chat-icons);
}

.iconError {
  color: var(--badge-red);
}

.mentionErrorMessage {
  position: absolute;
  font-size: 12px;
  right: 0;
  top: 25px;
  white-space: nowrap;
  color: var(--badge-red);
}

.commentFormButton svg {
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.commentFormButton:hover {
  color: var(--button-green);
}

.clearTextButton {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
}

.clearBtnWithScroll {
  right: 25px;
}

.mention {
  display: flex;
  align-items: center;
  gap: 5px;
}

.clearMentions,
.clearTextButton {
  color: var(--controls-grey);
}

.clearMentions:hover,
.clearTextButton:hover {
  color: var(--badge-red);
}

.attachmentsContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.attachment {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 300px;
  min-width: 50px;
  min-height: 150px;
}

.attachmentRemoveButton {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #ccc;
}

.attachmentRemoveButton:hover {
  color: #fff;
}

.attachmentImage {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: auto;
  max-height: 150px;
  border-radius: 5px;
}

.attachmentFiller {
  background-color: #f8f9fb;
  height: 100%;
  width: 200px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.attachmentIcon {
  font-size: 60px;
  color: #4d4d4d;
}

.attachmentName {
  margin: 0;
  text-align: center;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emojiContainer {
  display: flex;
  position: relative;
}

.emojiPicker {
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 2;
}

.chatForm .emojiPicker {
  right: 0;
}

@media (min-width: 768px) {
  .emojiPicker {
    right: 0;
  }

  .input {
    padding: 15px 40px 15px 15px;
  }

  .formControls {
    display: flex;
    gap: 20px;
  }

  .tooltips {
    gap: 12px;
  }

  .formButton {
    height: 42px;
    padding: 0 50px;
  }

  .commentFormButton {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  .commentFormButton svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  .response {
    font-size: 15px;
    line-height: 18px;
  }

  .formBottomBar {
    padding-left: 15px;
  }
}
