.suggestedHighlightCard {
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  border: 3px solid var(--user-post-header);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.suggestedHighlightCardModerator {
  border: 3px solid var(--moderator-post-header);
}

.text {
  padding: 2px;
  border-radius: 4px;
  font-size: 12px;
  background-color: var(--user-post-bg);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colorsContainer {
  display: flex;
  gap: 4px;
}

.colorModalWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonConfirm {
  box-shadow: none;
  border-radius: 12px;
  height: 24px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
}

.buttonConfirm:hover {
  box-shadow: none;
}

.buttonRemove {
  margin: 0 auto;
  font-size: 10px;
  font-weight: 500;
  color: var(--tagged-chat-btn);
  max-height: 0;
  width: fit-content;
}

.buttonRemove:hover {
  color: var(--button-green);
  background-color: transparent;
}
