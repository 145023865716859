.container {
  width: 290px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--post-list-bg);
  border-radius: 10px;
}

.userList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filterWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
}

.roleFilterButton:hover {
  color: var(--button-green);
}

.roleFilterButtonActive {
  border-bottom: 1px solid var(--drawer-highlights-tab);
  font-weight: 700;
}

.roleFilterButtonActive:hover {
  border-color: var(--button-green);
}

.filterUsersBtn {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--group-title-text);
  cursor: pointer;
  border: none;
}

.memberCount {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  padding: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  line-height: 0;
  background-color: var(--button-green);
}

.filterUsersBtn:focus {
  outline: none;
}

.filterUsersBtn:hover .buttonArrowIcon {
  opacity: 0.7;
}

.buttonArrowIconActive {
  transform: rotate(180deg);
}

.popupWrapper {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 1;
}

.statusBar {
  display: flex;
  align-items: center;
  gap: 6px;
}

.statusItem {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.statusItemBlue {
  background: #2b9df6;
}

.statusItemWhite {
  background: #eff0f5;
}

.statusItemRed {
  background: #e73b39;
}

@media (min-width: 768px) {
  .container {
    width: 430px;
    padding: 12px;
  }

  .userList {
    gap: 20px;
  }
}

@media (min-width: 1440px) {
  .container {
    position: sticky;
    top: 75px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .expandContainer {
    min-height: 350px;
  }
}
