.modalWrapper {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

.tagWrapper {
  display: inline-block;
  margin: 0 5px 5px 0;
}

.tag {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  background-image: linear-gradient(to right, rgba(255 255 255/60%), rgba(255 255 255/60%));
  /*this mask makes background-color a bit lighter(transparent) */
}

.tagSmall {
  font-size: 8px;
  padding: 3px 6px;
  gap: 2px;
}

.tagIconSmall {
  width: 12px;
  height: 12px;
}

.addTagsButton {
  width: 34px;
  height: 34px;
}

.buttonActive,
.buttonActive:hover {
  background-color: var(--button-green);
}

.addTagsIcon {
  color: var(--main-text-color);
}

.iconActive {
  color: #fff;
}

.textNoTags {
  color: var(--secondary-text-color);
  font-weight: 500;
  font-size: 14px;
}
