:root {
  --primary-color: #08745c;
  --controls-grey: #596973;
  --btn-disabled: #bdbdbd;
  --post-list-bg: #eff1f5;
  --green-transparent-bg: #258e6126;
  --main-text-color: #1c1616;
  --secondary-text-color: #5b5858;
  --button-green: #258e61;
  --group-title-text: #037358;
  --button-shadow: #00000050;
  --popup-shadow: rgba(0, 0, 0, 0.3);
  --btn-shadow: rgba(0,0,0,0.5);
  --activity-hover-shadow: #0000002e;
  --badge-red: #e22929;
  --mandatory-border: #5ca2dd;
  --status-moderator-text: #c27f6c;
  --header-default-bg: #d3d3d3;
  --header-transparent-bg: rgba(0, 0, 0, 0.2);
  --activity-selected: #eaffea;
  --activity-hover: #f3f5f0;
  --people-controls: #d1d9e2;
  --people-input-bg: #f8f9fb;
  --people-input: #5f6d7e;
  --activity-avatar-bg: rgba(220, 120, 3, 0.16);
  --activity-icon-color: #dc7803;
  --moderator-post-header: #f39c12;
  --moderator-post-bg: #fde9ca;
  --moderator-controls: #fffbdb;
  --user-post-header: #c5c9db;
  --user-post-bg: #eff0f5;
  --avatar-border: #3980ea;
  --tagged-chat-btn: #a5aac2;
  --chat-content: #d1d5e9;
  --chat-icons: #6d7296;
  --summary-text: #303c44;
  --statistic-header-orange: #ef860b;
  --statistic-header-blue: #1da1eb;
  --statistic-header-purple: #7436c3;
  --icon-default-bg: linear-gradient(45deg, #67bb6a 0%, #67bb6a 60%, #4aa54d 100%);
  --reply-border-color: #41c22c;
  --reply-body-color: #258e61;
  --drawer-highlights-tab: #342c2c;
  --text-disabled: #9ca0ba;
}
