.questionScale {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;
  line-height: 14px;
  border-bottom: 2px dashed var(--button-green);
}

.questionTitle {
  letter-spacing: 1px;
}

.scaleContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.highAndLowRatingCards {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.scaleWrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding-bottom: 12px;
}

.middleCards {
  width: 100%;
  margin-top: 16px;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  border-top: 1px solid var(--text-disabled);
  font-size: 9px;
  line-height: 10px;
  font-weight: 500;
  color: var(--controls-grey);
}

.scaleCard {
  white-space: nowrap;
}

.numberInCircle {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 700;
  border: 4px solid var(--user-post-bg);
  color: #fff;
}

.highRatingCard {
  background-color: var(--button-green);
}

.lowRatingCard {
  background-color: var(--badge-red);
}

@media (min-width: 768px) {
  .questionScale {
    gap: 20px;
    font-size: 16px;
    line-height: 20px;
  }

  .scaleWrapper {
    padding-bottom: 20px;
  }

  .numberInCircle {
    width: 44px;
    height: 44px;
  }

  .middleCards {
    font-size: 12px;
    line-height: 14px;
    margin-top: 22px;
    padding-top: 8px;
  }
}