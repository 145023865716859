.drawer {
  position: sticky;
  top: 0;
  align-self: flex-start;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawer > div {
  background-color: transparent;
  border: none;
  position: static;
}

.content {
  display: flex;
  gap: 20px;
  width: 100%;
}

.closeSidebarButtonContainer {
  display: flex;
  justify-content: center;
  padding: 30px 12px 0 16px;
}

.closeSidebarButton {
  color: #000;
}

.navigation {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  gap: 20px;
  padding: 30px 12px 20px 16px;
}

.drawerMobile .closeSidebarButtonContainer {
  margin-top: 0;
}

.link {
  color: #000;
}

.linkIconContainer {
  height: 28px;
  width: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  border-radius: 6px;
  background: var(--user-post-bg);
}

.linkIconContainer:hover,
.linkIconContainer:focus {
  background: #258e61;
  color: #fff;
}

.link.linkActive .linkIconContainer {
  background: #1c1616;
  color: #fff;
  cursor: default;
}

.mask {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
}

/* for drawer to be 100% width */
.navigationDrawer > div[tabindex='-1'] {
  /*width: 100%;*/
}

.wrapper {
  display: flex;
}

.contentWrapper {
  padding: 20px 20px 20px 0;
  position: relative;
  flex-basis: 100%;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.mobileBurger {
  padding: 8px 10px 9px;
}

@media (min-width: 768px) {
  .contentWrapper {
    padding: 20px 20px 20px 0;
  }

  .linkIconContainer {
    width: 50px;
    height: 50px;
  }

  .linkIcon {
    width: 24px;
    height: 24px;
  }

  .navigation {
    padding: 30px 20px 20px;
  }

  .mobileBurger {
    display: none;
  }
}

@media (min-width: 1024px) {
  .drawerOpened.drawerMobile {
    width: 84px;
  }
}

@media (min-width: 1440px) {
  .closeSidebarButton {
    display: none;
  }
}