.drawer {
  width: 100%;
  top: 60px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 60px 40px 40px;
  background-color: var(--user-post-bg);
}

.title {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}

.tabs {
  display: flex;
  gap: 12px;
}

.closeButton {
  position: absolute;
  top: 60px;
  right: 40px;
  padding: 7px;
  background-color: #fff;
}

.content {
  padding: 40px;
}

@media (min-width: 768px) {
  .drawer {
    top: 400px;
  }
}

@media (min-width: 1024px) {
  .drawer {
    top: 0;
    max-width: 585px;
  }
}
