.wrapper {
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 20px 0;
}

.headerText {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.listContainer {
  display: none;
}

.contentWrapper {
  flex: 1 1 100%;
}

.drawerContent {
  max-width: 80vw;
  min-width: 460px;
  display: flex;
  gap: 12px;
}

.drawerTitle {
  font-weight: 600;
  margin: 5px 0 20px;
}

.mobileBurger {
  padding: 2px;
}

@media (min-width: 768px) {
  .headerText {
    font-size: 24px;
  }

  .drawerTitle {
    margin-bottom: 25px;
  }
}

@media (min-width: 1024px) {
  .main {
    display: flex;
    gap: 20px;
  }

  .rowListContainer {
    display: block;
    flex: 0 1 100%;
  }

  .headerText {
    font-size: 28px;
  }
}

@media (min-width: 1440px) {
  .listContainer {
    display: block;
  }
}
