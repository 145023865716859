.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--post-list-bg);
  border-radius: 6px;
}

.personGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.imageSize {
  width: 26px;
  height: 26px;
}

.userInfo {
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userName {
  font-size: 16px;
  font-weight: 500;
  color: var(--main-text-color);
}

.highlightsCount {
  font-size: 10px;
  font-weight: 500;
  color: var(--controls-grey);
}

.highlightsList {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--user-post-header);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.themeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border: 2px solid var(--user-post-header);
  border-radius: 50%;
  color: var(--button-green);
}

.themeIcon svg {
  width: 16px;
  height: 16px;
}

@media (min-width: 768px) {
  .highlightsHeader {
    height: 46px;
  }
}
