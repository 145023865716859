.highlight {
  border-radius: 4px;
  cursor: pointer;
}

.highlight:hover {
  opacity: 85%;
  outline: 1px solid var(--controls-grey);
}

.text {
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
}

.textSurveyAnswer {
  font-size: 12px;
  line-height: 14px;
  color: var(--button-green);
}

@media (min-width: 768px) {
  .text {
    font-size: 16px;
    line-height: 22px;
  }
}
