.imagesSection {
  padding: 12px 2px 12px 12px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.imageCard {
  position: relative;
  border-radius: 6px;
}

.imageCard:hover {
  box-shadow: 0 0 8px var(--button-green);
}

.imageCard:hover .imageControls {
  display: flex;
}

.image {
  display: block;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}

.imageControls {
  display: none;
  position: absolute;
  right: 8px;
  bottom: 8px;
  gap: 8px;
}

.iconButton {
  width: 24px;
  height: 24px;
  background-color: var(--button-shadow);
  color: #fff;
}

.iconButton:hover {
  background-color: #fff;
  color: var(--button-green);
}
