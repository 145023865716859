.header {
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  background: var(--user-post-bg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 2px var(--controls-grey);
  z-index: 3;
}

.showHeaderBackground {
  height: 400px;
}

.wrapper {
  padding: 0 16px;
  margin: 0 auto;
  backdrop-filter: blur(5px);
}

.showHeaderBackground .wrapper {
  background-color: var(--header-transparent-bg);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  height: 60px;
}

.projectInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  height: 100%;
  width: 90px;
  position: relative;
  top: 5px;
}

.verticalDivider {
  width: 2px;
  height: 20px;
}

.projectName {
  max-width: 120px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profileAvatarContainer {
  height: 40px;
  flex-basis: 40px;
  padding: 2px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 2px solid #fff;
}

.avatar {
  width: 34px;
  height: 34px;
}

.profileAvatarImg {
  height: 100%;
}

.profileName {
  display: none;
}

.badgeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #fff;
  color: var(--main-text-color);
  border-radius: 50%;
}

.badgeWrapper svg {
  scale: 80%;
}

.badgeWrapper:hover svg {
  scale: 105%;
  transition: scale ease-in-out 200ms;
}

.showHeaderBackground .badgeWrapper {
  color: #fff;
  background-color: var(--header-transparent-bg);
}

@media (min-width: 480px) {
  .projectName {
    max-width: 270px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    padding: 0 40px;
  }

  .projectInfo {
    gap: 20px;
  }

  .projectName {
    max-width: unset;
    font-size: 20px;
  }

  .profileName {
    display: block;
    color: var(--main-text-color);
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    white-space: nowrap;
  }

  .showHeaderBackground .profileName {
    color: #fff;
  }

  .badgeWrapper {
    width: 34px;
    height: 34px;
  }

  .badgeWrapper svg {
    scale: unset;
  }
}
