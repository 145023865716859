.loader {
  margin-top: 30px;
}

.activityPostsWrapper {
  position: relative;
  margin-bottom: 20px;
}

.postsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 5px 20px;
  border-radius: 6px 6px 0 0;
  background-color: var(--user-post-header);
}

.postsTabs {
  display: flex;
  gap: 20px;
}

.postTabWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.postsTab {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: var(--drawer-highlights-tab);
}

.postsTab:hover {
  color: var(--button-green);
}

.postsTabActive {
  border-bottom: 1px solid var(--drawer-highlights-tab);
  font-weight: 700;
}

.postsTabActive:hover {
  border-color: var(--button-green);
}

.unreadPosts {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 22px;
  height: 22px;
  padding: 2px;
  background-color: var(--badge-red);
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
}

.pinnedPosts {
  margin-bottom: 20px;
}

.noPostsMessage {
  text-align: center;
  font-weight: 500;
  margin: 30px 0;
}

.activityWrapper {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
}

.captureTitle {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #037358;
}

.infoBox {
  border-radius: 10px;
  background: #037358;
  padding: 5px 10px;
  color: #fff;
  line-height: 24px;
  font-weight: 500;
}

.textLeft {
  text-align: left;
}

.ul {
  margin-left: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.previewImg {
  display: block;
  max-width: 100%;
}

@media (min-width: 768px) {
  .activityWrapper {
    border-radius: 20px;
    padding: 25px;
  }

  .postsTab {
    font-size: 14px;
    line-height: 14px;
  }

  .unreadPosts {
    min-width: 26px;
    height: 26px;
    font-size: 12px;
    line-height: 12px;
  }
}
