.filesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.attachmentWrapper {
  display: block;
  width: fit-content;
  text-decoration: none;
  color: inherit;
}

.audioAttachmentWrapper {
  display: flex;
}

.attachmentFiller {
  background-color: #f8f9fb;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.attachmentFiller:hover {
  color: var(--secondary-text-color);
}

.attachmentIcon {
  font-size: 40px;
}

.attachmentName {
  margin: 0;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.audio {
  flex: 1 1 100%;
  max-width: 500px;
  width: 0; /*needs for firefox, width: auto doesn't affect audio player in mozilla'*/
}

.attachmentNameAudio {
  font-size: 14px;
  margin: 0;
}
