.imageContainer {
  display: flex;
  justify-content: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
}

.image {
  max-width: 100%;
  max-height: 100vh;
}

.imageIndex,
.carouselButton {
  position: absolute;
}

.imageIndex {
  top: 30px;
  left: 20px;
  color: #fff;
}

.carouselButton {
  top: 50%;
  background-color: var(--controls-grey);
  color: #fff;
  opacity: 0.5;
}

.buttonPrevious,
.buttonNext {
  transform: translateY(-50%);
}

.buttonPrevious {
  left: 0;
}

.buttonNext {
  right: 0;
}

.closeButton {
  top: 0;
  right: 0;
}
