.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #85837e;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 500px;
  padding: 30px 0;
  border-radius: 10px;
  background-color: #d9d6d0;
}

.warningIcon {
  font-size: 70px;
}
.warningIcon,
.warningText {
  color: #85837e;
}
