.survey {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.questionTitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #000;
}

.formAnswer {
  font-size: 16px;
  line-height: 20px;
}

.brandName,
.websiteLink,
.time,
.number,
.nonRatingScaleAnswer,
.fileLinkText,
.brand {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: var(--button-green);
}

.ranking {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.rankItem {
  display: flex;
  gap: 12px;
  align-items: center;
}

.rankNumberWrapper {
  padding: 3px;
  border: 2px solid var(--user-post-header);
  border-radius: 50%;
}

.rankNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--button-green);
  border-radius: 50%;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #fff;
}

.websites {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.brands {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.website,
.file,
.time {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nonRatingScaleAnswer,
.brand {
  display: inline-block;
  padding: 6px 10px;
  border: 2px solid var(--user-post-header);
  border-radius: 20px;
}

.websiteLink {
  position: relative;
  text-decoration: none;
  color: var(--button-green);
}

.websiteLink::before,
.websiteLink::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -0.1rem;
  display: block;
  width: 100%;
  height: 1px;
  background: var(--button-green);
  transition: 1.1s cubic-bezier(0.19, 1, 0.22, 1);
}

.websiteLink::before {
  transform: scaleX(0);
  transform-origin: left;
}

.websiteLink::after {
  transform-origin: right;
  transition-delay: 0.25s;
}

.websiteLink:hover::before {
  transform: scaleX(1);
  transition-delay: 0.25s;
}

.websiteLink:hover::after {
  transform: scaleX(0);
  transition-delay: 0s;
}

.files {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.fileLink {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border-radius: 20px;
  text-decoration: none;
  background-color: #fff;
  color: var(--main-text-color);
  transition:
    background-color 0.3s ease-out,
    color 0.3s ease-out;
}

.fileLinkText {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.fileIcon {
  flex-shrink: 0;
}

.fileLink:hover {
  background-color: var(--user-post-bg);
  color: var(--button-green);
}

.carImages {
  display: flex;
  flex-wrap: wrap;
}

.carImageWrapper {
  padding: 0 5px;
  border-radius: 6px;
}

.carImage {
  width: 100%;
  border-radius: 6px;
}

.scale {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 16px 0;
  height: 2px;
  background-color: var(--user-post-header);
}

.scaleDot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid var(--user-post-header);
  border-radius: 50%;
}

.scaleDotActive {
  width: 30px;
  height: 30px;
}

.innerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border: 1px solid var(--user-post-header);
  border-radius: 50%;
  background-color: var(--user-post-bg);
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #fff;
}

.scaleDotActive .innerDot {
  width: 20px;
  height: 20px;
  border: none;
}

.innerDotActive {
  background-color: var(--button-green);
}

.webIcon {
  width: 24px;
  height: 24px;
}

.paymentIcon,
.clockIcon,
.fileIcon {
  width: 28px;
  height: 28px;
}

@media (min-width: 768px) {
  .survey {
    gap: 20px;
  }

  .questionTitle {
    font-size: 18px;
    line-height: 22px;
  }

  .brandName,
  .websiteLink,
  .time,
  .number,
  .nonRatingScaleAnswer,
  .brand {
    font-size: 20px;
    line-height: 24px;
  }

  .nonRatingScaleAnswer,
  .brand {
    padding: 10px 20px;
  }

  .ranking {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .rankItem {
    width: 33%;
  }

  .scale {
    margin: 20px 0;
  }

  .scaleDot {
    width: 26px;
    height: 26px;
  }

  .innerDot {
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
  }

  .scaleDotActive {
    width: 40px;
    height: 40px;
    border: 2px solid var(--button-green);
  }

  .scaleDotActive .innerDot {
    width: 28px;
    height: 28px;
  }

  .rankNumberWrapper {
    padding: 5px;
  }

  .rankNumber {
    width: 28px;
    height: 28px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
  }

  .webIcon {
    width: 40px;
    height: 40px;
  }

  .paymentIcon,
  .clockIcon,
  .fileIcon {
    width: 44px;
    height: 44px;
  }

  .fileLinkText {
    width: 180px;
    font-size: 24px;
    line-height: 28px;
  }

  .website,
  .file,
  .time,
  .nonRatingScaleAnswer {
    gap: 10px;
  }

  .brands {
    gap: 20px;
  }
}

@media (min-width: 1440px) {
  .questionTitle {
    font-size: 20px;
    line-height: 24px;
  }
}
