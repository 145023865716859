.mainModal,
.addNewPreset,
.editPresets {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 2;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 -1px 12px var(--popup-shadow);
}

.mainModal {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 210px;
  padding: 12px;
}

.presetForm {
  display: flex;
  gap: 8px;
}

.presetInput {
  flex: 1 1 100%;
  padding: 0 5px;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid var(--controls-grey);
  border-radius: 12px;
}

.presetInput:focus,
.presetNameInput,
.presetColorInput {
  outline: none;
}

.presetInput::placeholder {
  padding-left: 25px;
}

.presetButton,
.buttonIcon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.colors {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: -12px;
  padding-right: 12px;
}

.editButton,
.addPresetButton {
  width: 100%;
  height: 24px;
  background-color: var(--user-post-bg);
  border-radius: 12px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.addNewPreset,
.editPresets {
  width: 214px;
  padding: 20px 12px;
}

.addPresetTitle,
.editPresetTitle {
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.presetNameTitle,
.colorPaletteTitle {
  margin-bottom: 4px;
  font-size: 8px;
  line-height: 10px;
}

.colorPaletteTitle {
  margin-bottom: 8px;
}

.presetNameInput,
.presetColorInput {
  width: 100%;
  margin-bottom: 16px;
  padding: 5px 8px;
  border: 1px solid var(--controls-grey);
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  caret-color: var(--button-green);
}

.presetColorInput {
  border: 1px solid var(--button-green);
}

.invalidHex {
  border: 1px solid var(--badge-red);
}

.definedColorsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.colorPicker {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
}

.modalControls {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.hexError {
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 10px;
  line-height: 12px;
  color: var(--badge-red);
}

.button {
  width: 90px;
  padding: 4px 0;
  height: 24px;
  border-radius: 12px;
}

.editPresets {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.presetWrapper {
  display: flex;
  gap: 8px;
}

.presetName {
  flex: 1 1 100%;
}

.presetColor {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.presetsButton {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
