.pinPostPopup {
  position: absolute;
  z-index: 2;
  width: 250px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 4px var(--popup-shadow);
}

.pinPostPopupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: var(--user-post-header);
  border-radius: 5px 5px 0 0;
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatarWrapper {
  position: relative;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid var(--avatar-border);
}

.userAvatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.userInfo,
.mainContent {
  text-align: left;
}

.userName {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: var(--main-text-color);
}

.postCreationDate {
  font-size: 10px;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.deletePostButton {
  width: 24px;
  height: 24px;
  background-color: #fff;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #000;
}
