.questionTitle,
.totalAnswers,
.averageScore,
.manufacturerName {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}

.totalAnswers {
  flex-basis: 50px;
  text-align: right;
}

.questionTitle {
  margin-bottom: 20px;
  padding-right: 50px;
}

.ratingField {
  margin-bottom: 10px;
}

.answerOption {
  margin-left: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}

.statistics {
  display: flex;
  justify-content: space-between;
}

.averageScoreWrapper {
  flex-grow: 1;
  flex-shrink: 0;
}

.scaleWrapper {
  padding: 8px;
  border: 1px solid var(--user-post-header);
  border-radius: 14px;
}

.scale,
.scaleActive {
  height: 12px;
  border-radius: 6px;
}

.scale {
  position: relative;
  width: 100%;
  background-color: var(--user-post-header);
}

.scaleActive {
  position: absolute;
  background-color: var(--button-green);
}

.rangeDot {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: #fff;
  background-color: var(--button-green);
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 8px var(--button-shadow);
}

.rangeDotOnScale {
  position: absolute;
  right: 0;
  top: -15px;
  transform: translateX(50%);
}

.averageScore {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin: 20px 0 0;
}

.manufacturerColumn {
  width: 60%;
}

.thinnerManufacturerColumn {
  width: 40%;
}

.dataColumn {
  width: 15%;
}

.manufacturer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
}

.manufacturerDot {
  flex-shrink: 0;
  background-color: var(--avatar-border);
}

.tableDot {
  margin: 0 auto;
}

.tableHeading {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding-bottom: 10px;
}
