.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 250px;
  padding: 80px 20px 40px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid var(--user-post-bg);
  border-radius: 8px;
}

.deleteIcon {
  font-size: 72px;
  margin: -10px 0;
  color: var(--button-green);
}

.modalTitle {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 204px;
}

.modalButtons {
  display: flex;
  gap: 20px;
}

.modalButton {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  padding: 10px;
  min-width: 129px;
  border-radius: 4px;
}

.modalButtonDanger,
.modalButtonDanger:hover {
  background-color: var(--badge-red);
}

@media (min-width: 768px) {
  .deleteModal {
    padding: 80px 40px 40px;
  }

  .modalButtons {
    gap: 40px;
  }
}
