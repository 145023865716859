@import url('https://fonts.cdnfonts.com/css/sf-pro-display?styles=98774,98773,98770');
@import './constants/variables.css';
@import './components/activityDetailsDrawer/tabs/instructionsTab.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
  border: none;
  background-color: unset;
}

.showOnDesktop {
  display: none;
}

.video-react {
  font-family: inherit;
}

.popover {
  overflow: visible;
}

.video-react .video-react-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .showOnMobile {
    display: none;
  }

  .showOnDesktop {
    display: inherit;
  }
}

@media (min-width: 1440px) {
  .hideOnDesktop {
    display: none;
  }
}
