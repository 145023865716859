.inputWrapper {
  position: relative;
}

.input {
  width: 225px;
  padding: 5px 10px 5px;
  border: 1px solid var(--controls-grey);
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
}

.input:focus {
  outline: none;
  border: 1px solid var(--button-green);
}

.searchInput {
  padding-left: 25px;
  background-image: url('@/assets/icons/search.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: 8px 6px;
}

.clearTextButton {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  color: var(--controls-grey);
}

.clearTextButton:hover {
  color: var(--badge-red);
}

.clearTextButton,
.clearTextButton svg {
  width: 16px;
  min-width: 16px;
  height: 16px;
}