.filterUsersDropdown {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 4px;
  border-radius: 8px;
  box-shadow: 0 -1px 12px var(--popup-shadow);
}

.filterButton {
  min-width: 110px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 2px 5px 8px;
}

.filterButtonDisabled {
  color: var(--text-disabled);
  cursor: default;
}

.filterButton:hover {
  background-color: var(--user-post-bg);
}

.numberInCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 0;
  background-color: var(--user-post-bg);
  border: 2px solid var(--user-post-bg);
  color: #000;
}

.filterButton:hover .numberInCircle {
  background-color: var(--button-green);
  color: #fff;
  border-color: #fff;
}
