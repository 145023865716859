.startChatWrapper {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 12px 0;
}

.startChatBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  line-height: 14px;
  color: var(--tagged-chat-btn);
}

.startChatBtn:hover {
  color: var(--controls-grey);
}

.messagesNumber {
  display: grid;
  place-items: center;
  height: 16px;
  min-width: 16px;
  padding: 0 2px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  color: #fff;
  background-color: var(--button-green);
  border-radius: 50%;
}

.commentList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.taggedChatForm {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.chatAvatarSize {
  width: 26px;
  height: 26px;
}