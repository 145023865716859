.tabWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.tab {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--drawer-highlights-tab);
}

.tab:hover {
  color: var(--button-green);
}

.tabActive {
  border-bottom: 1px solid var(--drawer-highlights-tab);
  font-weight: 700;
}

.tabActive:hover {
  border-color: var(--button-green);
}

.unreadCount {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 22px;
  height: 22px;
  padding: 2px;
  background-color: var(--badge-red);
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
}
