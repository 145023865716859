.panelTrigger {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.circle {
  width: 16px;
  height: 16px;
  background-color: var(--user-post-header);
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
}

.moderatorPost .circle {
  background-color: var(--moderator-post-header);
}

.circleArrow {
  font-size: 14px;
  transition: transform linear 200ms;
}

.circleArrowExpanded {
  transform: rotate(90deg);
}

.heading {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.moderatorPost .heading {
  color: var(--moderator-post-header);
}

.contentList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}
